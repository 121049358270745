<template>
  <div>
  <div v-if="reconnecting" class="reconnecting">Reconnecting...</div>
  <Header class="sticky">
    <template v-slot:left>
      <div class="session-controls" v-if="selectedSession">
        <div class="name">Session #{{ selectedSession.id }} • <span>{{ new Date(selectedSession.startDate).toLocaleString() }}</span></div>
        <div class="name">{{ selectedSession.name }}</div>
        <!-- <button class="insead white autowidth tooltip">Finish session<span class="tooltiptext">This will conclude the session for you and all participants</span></button> -->
        <button class="insead white autowidth tooltip" @click="pauseSessionModal = true;" :class="{disabled: current.status == 2}" :disabled="current.status == 2">Broadcast message<span class="tooltiptext" v-if="current.status != 2">Send a message to everyone</span></button>
        <button class="insead white autowidth tooltip" @click="selfPacedOnModal = true;" :class="{disabled: current.status == 3}" :disabled="current.status == 3">Turn self-paced on<span class="tooltiptext" v-if="current.status != 3">When self pacing is on, participants can browse session resources freely</span></button>                  
      </div>
      <Modal v-if="pauseSessionModal" @close="pauseSessionModal = false;">      
        <h3 slot="header">Broadcast message</h3>
        <div slot="body">
          Broadcasting a message will pause all devices connected to this session!          
          <div class="title">
            Message to participants
            <div class="charCount">{{pauseSessionMessage.length}}/144 characters</div>
          </div>            
          <textarea v-model.trim="pauseSessionMessage" maxlength="144" placeholder="Type message to be transmitted to all participants"></textarea>
        </div>
        <div slot="footer">                
          <button class="insead white" @click="pauseSessionModal = false">Go back</button> 
          <button class="insead" :disabled="!pauseSessionMessage.length" :class="{disabled: !pauseSessionMessage.length}" @click="pauseSessionModal = false; pauseSession()">Broadcast message</button>               
        </div>
      </Modal>
      <Modal v-if="selfPacedOnModal" @close="selfPacedOnModal = false;">      
        <h3 slot="header">Self pacing</h3>
        <div slot="body">
          Allow your students to explore the session content freely unless you turn it off and start playing a resource for them.
        </div>
        <div slot="footer">                
          <button class="insead white" @click="selfPacedOnModal = false">Go back</button> 
          <button class="insead" @click="selfPacedOnModal = false; selfPacedOn()">Turn self pacing on</button>               
        </div>
      </Modal>
      <Modal v-if="selfPacedOffModal" @close="selfPacedOffModal = false;">      
        <h3 slot="header">Turn Self Pacing Off</h3>
        <div slot="body">
          Students will be taken back to the session waiting screen, interrupting any ongoing playback. They will not be able to browse content freely anymore.
        </div>
        <div slot="footer">                
          <button class="insead" @click="selfPacedOffModal = false">Go back</button> 
          <button class="insead red" @click="selfPacedOffModal = false; selfPacedOff()">Turn self pacing off</button>               
        </div>
      </Modal>
      <Modal v-if="exitSegmentModal" @close="exitSegmentModal = false;">      
        <h3 slot="header">End playback</h3>
        <div slot="body">
          This will stop playback for everyone regardless their progress, and bring them back to the session screen.
        </div>
        <div slot="footer">                
          <button class="insead" @click="exitSegmentModal = false">Go back</button> 
          <button class="insead red" @click="exitSegmentModal = false; exitSegment()">Yes, end playback</button>               
        </div>
      </Modal> 
    </template>
    <!-- <template v-slot:right>
      <div class="session-controls right">
        <button class="insead white autowidth"><span class="session-results-icon"></span>Session results</button>
      </div>
    </template> -->
  </Header>
  
  <div class="statusbar" v-if="selectedSession && (current.segment || current.status == 2 || current.status == 3)">

    <div class="self-paced" v-if="current.status == 3">
      <div class="status">SELF PACING: ON</div>
      <div class="info">
        <span class="message">Your students can view all session content freely</span>
        <button class="insead white autowidth" @click="selfPacedOffModal = true">Turn off</button>
      </div>
    </div>

    <div class="session-paused" v-if="current.status == 2">
      <div class="status orange"><span class="pause-icon"></span>session paused</div>
      <div class="info">
        <span class="message" v-if="current.message">{{current.message}}</span>
        <button class="insead white autowidth" @click="resumeSession">Resume session</button>
      </div>
    </div>

    <div class="session-status" v-if="current.segment && current.status == 1">
      <button class="insead autowidth" @click="exitSegmentModal = true;">
        <span class="stop-icon"></span>
        End playback
      </button>
      <!-- <button class="insead white autowidth">Segment results</button> -->
      
      <div class="thumbnail">
        <img :src="contentRoot + current.segment.icon" />
      </div>
      <div>
        <div class="title" style="padding-left:25px; position: relative;">{{currentStatusText}}
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto; position: absolute; left: 0; top: -2px;" width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" r="37" stroke-width="7" stroke="#539a55" stroke-dasharray="58.119464091411174 58.119464091411174" fill="none" stroke-linecap="round">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2.564102564102564s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
            </circle>  
            <polygon points="50 15, 100 100, 0 100" fill="#00684B" transform-origin="50 50" transform="rotate(90), scale(0.4), translate(0,-20)"/>
        </svg>
        <!-- [ldio] generated by https://loading.io/ -->
        </div>
        <div>{{current.segment.txt1}}</div>
      </div>
      <div v-if="current.conditions && current.conditions.length">
        <div class="title">Conditions</div>
        <div class="conditions"><span class="spacer" v-for="condition in current.conditions" :key="condition.condition"><span class="letter">{{condition.txt1}}</span></span></div>
      </div>
      <div v-if="current.conditions && current.conditions.length">
        <div class="title">Allocation</div>
        <div>{{current.allocation}}</div>
      </div>
      <!-- <div class="control">
        <button class="pause"></button>
      </div> -->
      <div>
        <div class="title">{{overallProgress}}% Overall completion <span class="help">?</span></div>
        <div class="progressbar">
          <div class="progress" v-bind:style="{ width: overallProgress+'%' }"></div>
        </div>
      </div>
    </div>

  </div>

  <main class="mainlayout sticky" :class="{extended: this.selectedSession && (this.current.segment != undefined || this.current.status == 2 || this.current.status == 3)}">    
 
    <Sidebar>      
      <div slot="body">
        <div class="sessionlist" v-show="sessions && sessions.length">
          <div class="title">SCHEDULED SESSIONS</div>

          <!-- <select class="select-css" v-model="selectedSession" @change="sessionSelected">
            <option disabled value=undefined v-if="!selectedSession">Select a session...</option>
            <!- <option disabled value="">Select a session...</option> ->
            <option v-for="session in sessions" v-bind:value="session" :key="session.id">
              #{{ session.id }} {{session.name}} 
            </option>
          </select> -->

          <vSelect
              :disabled="loading"  
              :options="sessions" 
              :filter="sessionSearch" 
              :clearable="false"
              label="id"
              v-model="selectedSession"
              @input="sessionSelected"
          >
            <template v-slot:option="session">              
              #{{ session.id }} {{session.name}}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"></span>
            </template> 
            <template #selected-option="{ id, name }">
              #{{ id }} {{name ? name.substring(0,12) : ''}}...
            </template>           
          </vSelect>

          <br>
          <!-- <span>Selected: #{{ selectedSession ? selectedSession.id : '--' }}</span>         -->
        </div>

        <div class="title" v-show="sessions && sessions.length">
          RESOURCES 
          <span @click="$router.push(`/sessions/${selectedSession.id}`)" title="Edit session contents">✎</span>
          <span style="font-size: 14px;" @click="$router.push(`/sessions/${selectedSession.id}/admins`)" title="Choose who has dashboard access for this session">🔐</span>
          <router-link class="update" v-if="updateAvailable" :to="`/sessions/${selectedSession.id}`">Update available <b>🗘</b></router-link>
        </div>

        <ul id="demo" class="tree" v-show="sessions && sessions.length">
          <TreeItem
            v-if="selectedSession && selectedTreeItem"
            class="treeitem"
            @select="treeItemSelected"
            :item="selectedSessionStructure"
            :selectedItem="selectedTreeItem"
            :level="0"
          ></TreeItem>
        </ul>

        <div class="title" style="padding: 25px;" v-show="!sessions || !sessions.length">NO SCHEDULED SESSIONS</div>
      </div>
    </Sidebar>

    <div class="content">

      <div class="breadcrumbs">
        <!-- <div class="number">1.1</div> -->
        <!-- <span>#1 Folder opened</span> → <span>#4.1 Experience</span> → <span>Segment</span> -->        
        <template v-for="(crumb,idx) in breadcrumbs">
          <span class="crumb" :key="idx" @click="selectedTreeItem = crumb">
            {{crumb.txt1}}
          </span>
          <span v-if="idx < breadcrumbs.length-1" :key="idx+'x'">→</span>
        </template>    
        <span class="copy" @click="copyUrl" title="Copy link to segment">🔗</span>    
      </div>

      <div class="folders" v-if="selectedTreeItem && selectedTreeItem.type == 'list'">        
        <div class="folder" v-for="item in selectedTreeItem.list" :key="item.txt1"
        @click="selectedTreeItem = item">
          <div class="preview" 
            :class="{hasimage: item.icon}"
            v-bind:style="{ backgroundImage: 'url(' + (item.icon ? (contentRoot + item.icon) : require('@/assets/folder.svg')) + ')' }"></div>
          <div class="info">
            <!-- <div class="number">1.1</div> -->
            <div class="title">{{item.txt1}}<br><span>{{item.list && item.list.length ? (item.list.length + (item.type == 'conditional' ? ' conditions' : ' subfolders')) : 'Segment'}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="segment" v-if="segment">   
        <div class="image">   
          <img :src="contentRoot + selectedTreeItem.icon" />
        </div>
        <div class="info">   
          <div class="conditions" v-if="conditions">
            <div v-for="condition in conditions" :key="condition.condition"
              class="condition"
              :class="{active: selectedTreeItem == condition}"
              @click="selectedTreeItem = condition"
            >
              <span v-if="condition.condition != 'summary'" class="letter">{{condition.txt1}}</span> 
              <!--<span class="name">{{condition.condition != 'summary' ? condition.txt1 : 'Summary'}}</span> -->
              <span v-if="condition.condition == 'summary'" class="name">Summary</span>
            </div>
          </div>
          <div class="text">
            {{selectedTreeItem.txt2}}
            <br>
            <br>
            <!-- <button class="insead white autowidth"><span class="preview-icon"></span>Preview video</button> <span class="previewbtntooltip">Only you will see playback</span>   -->
            <!-- <div v-if="selectedTreeItem.segJson && selectedTreeItem.segJson.items" class="items">
              <div v-for="item in selectedTreeItem.segJson.items" :key="item.id">
                {{item.type}}
              </div>
            </div> -->
          </div>
        </div>        
      </div>
      <div class="segment-controls" v-if="segment">
          <div class="control" v-if="conditions && !conditionsWithRequirements">
            <div class="title">CONDITION(S) TO PLAY</div>
            <button ref="conditionsdropdown" class="insead white autowidth dropdown" onclick="this.toggleAttribute('opened')">
              <template v-if="checkedConditions.length"> 
                <span class="spacer" v-for="condition in checkedConditions" :key="condition.condition"><span class="letter">{{condition.txt1}}</span></span>
              </template>
              <span v-else>
                Select conditions
              </span>
              <div class="menuitems">
                <label v-for="condition in conditions" :key="condition.condition" :value="condition.condition" v-show="condition.condition != 'summary'">                
                    <input type="checkbox" :id="condition.condition" :value="condition" v-model="checkedConditions">
                    <span> <!--{{condition.condition}}. -->{{condition.txt1}}</span> 
                </label>            
              </div>
            </button>  
          </div>
          <div class="control" v-if="conditions && conditionsWithRequirements">
            <div class="title">CONDITION(S) TO PLAY</div>
            <select class="select-css" disabled>
              <option disabled selected>Automatic</option>
            </select>
          </div>
          <div class="control" v-if="conditions">
            <div class="title">ALLOCATION</div>
             <select class="select-css" disabled>
              <!-- <option disabled value="" v-if="selectedSession == ''">Select a session...</option>
              <option v-for="session in sessions" v-bind:value="session" :key="session.id">
                Session #{{ session.id }}
              </option> -->
              <option disabled selected>Automatic</option>
              <!-- <option>25% % / 25% / 25% / 25%</option>
              <option>50% / 50%</option> -->
            </select>
          </div>
          <div class="control" v-if="conditions">
            <div class="title">PLAY FOR</div>
             <select class="select-css" disabled>              
              <option disabled selected>Automatic</option>
            </select>
          </div>
          <div class="control">
            <div class="title" v-if="conditions">&nbsp;</div>
             <button 
                @click="playSegmentModal = true" 
                class="insead autowidth" 
                :disabled="playResourceDisabled"
                :class="{disabled: playResourceDisabled}"
                :title="playResourceDisabled ? 'Select at least one condition' : ''"
              >
                <span class="play-icon"></span>Play Segment
              </button>  
             <Modal v-if="playSegmentModal" @close="playSegmentModal = false;" class="playsegmentmodal">      
              <h3 slot="header">Playing content</h3>
              <div slot="body">
                When playing back content, your chosen experience will be shown on all the devices you’ve selected. Make sure that all students are ready.
              </div>
              <div slot="footer">                
                <button class="insead white" @click="playSegmentModal = false">Don't play</button> 
                <button class="insead" @click="playSegmentModal = false; playSegment()">Play the Segment</button>               
              </div>
            </Modal>  
          </div>
          <div style="clear:both;"></div>          
      </div>

      <!-- <div class="segment-results" v-if="segment">
        <span class="title">Results</span>
        <span class="title active">Heatmap</span>
      </div> -->
      <div class="segment-results" v-if="segment">
        <span class="title" :class="{active: analyticsTab == 'questions'}" v-if="questions && questions.length" @click="analyticsTab = 'questions'">Questions</span> 
        <span class="title" :class="{active: analyticsTab == 'heatmap'}" v-if="heatmapVideoItems && heatmapVideoItems.length" @click="analyticsTab = 'heatmap'">Behavior</span>
        <span class="title" :class="{active: analyticsTab == 'hotspots'}" v-if="hotspots && hotspots.length" @click="analyticsTab = 'hotspots'">Interactions</span>
        <button class="insead autowidth white left" :disabled="archiveDisabled" @click="archiveResultsModal = true">Archive current results</button>        
        <select class="select-css timerangedropdown" v-model="resultsSerial" @change="resultsGroupSelected">                    
          <option v-for="group in resultsGroups" v-bind:value="group.serial" :key="group.serial" :selected="group.serial == resultsSerial">
            {{ group.serial == null ? 'Current' : group.serial }}{{group.from || group.to ? ':' : ''}} {{ group.from ? new Date(group.from).toLocaleDateString() : ''}} {{group.from && group.to ? '-' : ''}} {{group.to ? new Date(group.to).toLocaleDateString() : ''}} 
          </option>
          <option value="-1" v-if="resultsGroups.length > 1">All</option>          
        </select>
        <button class="insead autowidth white left" v-show="analyticsTab == 'questions'" :disabled="!results || results.length == 0" style="margin-left: 10px;" @click="exportResultsModal = true">Export CSV</button>   
        <Modal v-if="exportResultsModal" @close="exportResultsModal = false;" class="exportresultsmodal">      
          <h3 slot="header">Export questions results</h3>
          <div slot="body">            
            If you have collected user metadata during the session to distinguish users, then this metadata key will be used as the external user identifier (the default used in most experiences is called "pnumber").
            <br>
            <br>
            Otherwise, questions results export is grouped by User ID by default (in guest mode, without real-life user accounts this is the VR device itself). 
            <!-- <label>Group by</label>
            <br style="clear:both;">
            <input type="radio" v-model="externalUserId" :value="undefined" /> default UserId
            <br>
            <input type="radio" v-model="externalUserId" :value="'pnumber'" /> 'pnumber' user metadata -->
          </div>
          <div slot="footer">                
            <button class="insead white" @click="exportResultsModal = false">Cancel</button> 
            <button class="insead" @click="exportFlattenedResults(); exportResultsModal = false;">Export</button>                             
          </div>
        </Modal> 
        <Modal v-if="archiveResultsModal" @close="archiveResultsModal = false;" class="archiveresultsmodal">      
          <h3 slot="header">Archive current results</h3>
          <div slot="body">
            When archiving, the current session results (latest set of answers and heatmap data) will be reset, but not deleted, and still available as historical data.
          </div>
          <div slot="footer">                
            <button class="insead white" @click="archiveResultsModal = false">Cancel</button> 
            <button class="insead" @click="archiveResultsModal = false; archiveResults()">Archive current results</button>                             
          </div>
        </Modal>  
        <!-- <button class="insead white autowidth"><span class="session-results-icon"></span>Detailed results page</button> -->
        <button class="insead white autowidth right" @click="presentResults"><span class="session-results-icon"></span>Present results</button>
        <br style="clear: both;">

        <Results 
          ref="results"
          v-show="analyticsTab == 'questions'"
          v-bind:conditions="conditions" 
          v-bind:questions="questions"
          v-bind:results="results" 
          v-bind:contentRoot="contentRoot" 
          v-bind:selectedCondition="segmentHeatmapCondition"
          @conditionSelected="segmentHeatmapCondition = $event"
        />

        <div v-show="analyticsTab == 'heatmap'" v-if="heatmapVideoItems && heatmapVideoItems.length">
          
          <div class="heatmapconditions" v-if="conditions">
            <div v-for="condition in conditions.filter(c => c.condition != 'summary')" :key="condition.condition"
              class="condition"
              :class="{active: segmentHeatmapCondition == condition}"
              @click="segmentHeatmapCondition = condition"
            >
              <span class="letter">{{condition.txt1}}</span>
            </div>      
          </div>

          <div class="items">
            Segment (id: {{segment.id}}{{conditions && segmentHeatmapCondition ? `, conditionId: ${segmentHeatmapCondition.id}` : ''}}) video items with heatmap, click to select one: 
            <div v-for="item in heatmapVideoItems" :key="item.id">
              <div class="heatmapvideoitem" :class="{selected: item.id && heatmapItem && item.id == heatmapItem.id}" @click="heatmapItem=item" title="Click to load analytics/heatmap data for this video">
                title: {{item.title ? item.title : '-'}}, id: {{item.id ? item.id : 'missing'}}, file: {{item.heatmapWebVid ? item.heatmapWebVid : 'missing'}}
              </div>              
              <!-- <div v-else>
                type: {{item.type}}
              </div> -->
            </div>
            <!-- <div v-if="!selectedTreeItem.segJson.items.map(i => i.type == 'vid').length">
                segment contains no video items
            </div> -->
          </div>
          <br>          
          <Heatmap ref="heatmap" v-if="selectedSession" 
            :sessionId="selectedSession.id" 
            :segmentId="(segment && conditions && segmentHeatmapCondition) ? segmentHeatmapCondition.id : (segment ? segment.id : undefined)" 
            :item="heatmapItem"
            :contentRoot="contentRoot"            
            :serial="resultsSerial"
          />
        </div>    

        <div v-show="analyticsTab == 'hotspots'" v-if="hotspots && hotspots.length">          
          <!-- TODO Might need to rework condition selection:
               Currently part of the Results component, because of the "Presentation mode" but probably Hotspots and Heatmap will also need to be worked into this presentation mode.    
               Once this will be clear, then it should be an individual component or something, and the value just injected as it is currently into the Heatmap (hence the name segmentHeatmapCondition)
          -->
          <Hotspots ref="hotspots" v-if="selectedSession"
            :hotspots="hotspots"
            :results="results"
            :segmentId="(segment && conditions && segmentHeatmapCondition) ? segmentHeatmapCondition.id : (segment ? segment.id : undefined)"
            :labelName="segment.hotspotsLabelName"
          />     
        </div>   

        
      </div>

      <h2 v-if="!selectedSession">
      Please select one from your available sessions (in the dropdown on the left sidebar).
      </h2>

      <br style="clear:both;">
      <br style="clear:both;">      
      <br style="clear:both;">
      <br style="clear:both;">
      
      <button v-show="$store.getters.isAdmin || $store.getters.isOrgAdmin" @click="showPlayground = !showPlayground">{{(showPlayground ? 'Hide' : 'Show' )}} Development Playground</button>
      <br style="clear:both;">
      <br style="clear:both;">
      <div class="playground" style="clear:both;" v-bind:style="{display: showPlayground ? 'block' : 'none'}"> 

      <b>Online dashboard users (other than you):</b>  
        <div v-for="user in dashboardUsers" :key="user.connectionId">
          {{user.connectionId}}, {{user.name}}, {{user.timestamp}} {{ticker-user.timestamp > $constants.userOfflineTimeout ? 'timed out' : ''}}
        </div>  
        <div v-if="!dashboardUsers.length">4ever alone, nobody else is here</div>
      <br>
      <br>

      <!-- <div v-if="$store.getters.isAdmin">
        <b>Refresh public website contents (needs a few minutes to take effect):</b>
        <br>
        <button @click="triggerWebsiteRefresh">Trigger Refresh</button>
        <br>
        {{webhookMessage}}
      </div> -->

      <!-- <b>Heatmap:</b>       
      <div v-if="selectedTreeItem && selectedTreeItem.segJson && selectedTreeItem.segJson.items" class="items">
        Segment (id: {{segment.id}}) items: 
        <div v-for="item in selectedTreeItem.segJson.items" :key="item.id">
          <div v-if="item.type=='vid'" class="heatmapvideoitem" @click="heatmapItemId=item.id;heatmapVideoURL=item.heatmapWebVid" title="Click to load analytics/heatmap data for this video">
            type: {{item.type}}, id: {{item.id}}, heatmap: {{item.heatmap}}, heatmapWebVid: {{item.heatmapWebVid}}
          </div>
          <div v-else>
            type: {{item.type}}
          </div>
        </div>
      </div>
      <br>
      <Heatmap v-if="showPlayground && selectedSession" 
        :sessionId="selectedSession.id" 
        :segmentId="segment ? segment.id : undefined" 
        :itemId="heatmapItemId"
        :videoURL="heatmapWebVid"
      /> -->
      
      <br>
      <br>
      <b>PIN: </b> <br> 
      <!-- <input v-model="testMessage" type="text" placeholder="test message to send"> 
      <button @click="signalrtest">SignalR test message</button> -->
      <button @click="otptest">Generate PIN</button>
      <!-- <button @click="dashboardConnect">Dashboard connect to SignalR</button>   -->

      <br>
      <br>
      <br>
      <div>        
        <b>Activity logs:</b>  
        <br>
        <button @click="getLogs">Get/Refresh</button> 
        <table>
          <tr style="text-align: left;">
            <th>UserId</th>
            <th>Date</th>
            <th>Activity</th>
          </tr>
          <tr v-for="entry in activityLog" :key="entry.date" >
            <td>{{entry.userId}}</td>
            <td>{{entry.date}}</td>
            <td>{{entry.action.substring(0,100)}}</td>
          </tr>
        </table>
      </div>

      <br>
      <br>
      <br>
      <div class="mdm">        
        <b>MDM:</b>   
        <br>     
        File list last updated: {{selectedSession ? new Date(selectedSession.fileListDate).toLocaleString() : "???"}} (local time) &laquo;          
        <button :disabled="!selectedSession || (!selectedSession.custom && selectedSession.contentStructure)" @click="getS3files">Update S3 files</button> (for webContentRootPath based legacy sessions only)
        <br>
        <br>
        <button @click="createMDMjob" :disabled="this.s3files.length == 0" title="proxy files are not needed on device, and by convention their name should end with 'proxy', like 'somevideo_proxy.mp4'">Create MDM job (will skip .zip and 'proxy' files)</button>
        Account:
        <select v-model="mdmAccount">
          <option>Avris</option>
          <option>Insead</option>
          <option>InseadEvents</option>
        </select>
        <span v-if="!s3files.length || s3files.length == 0"><br>No files found for session :(<br></span>
        <div>
          <div v-if="selectedSession && selectedSession.fileTransferJobId">
            MDM job available (Id: {{selectedSession.fileTransferJobId}}, Created: {{selectedSession.fileTransferJobCreated ? new Date(selectedSession.fileTransferJobCreated).toLocaleString() : '--'}}, Last Applied: {{selectedSession.fileTransferJobApplied ? new Date(selectedSession.fileTransferJobApplied).toLocaleString() : '--'}}) {{(linkedDevices.length ? '' : ' but no linked devices in session')}}
            <div v-if="selectedSession.published > selectedSession.fileTransferJobCreated">Session content has been updated since the MDM job was created. For latest files re-create the MDM job.</div>
          </div>
          <div v-else>MDM job not available</div>
          <button :disabled="!selectedSession || !selectedSession.fileTransferJobId || !$store.getters.isAdmin || !linkedDevices.length" @click="applyFileTransferJob">Apply MDM file transfer job for {{linkedDevices.length}} linked devices in session</button>          
          <button :disabled="!selectedSession || !selectedSession.fileTransferJobId || !$store.getters.isAdmin || !linkedDevices.length" @click="applyEmptyFolderJob">Apply MDM "empty VR data folder" job for {{linkedDevices.length}} linked devices in session</button>   
        </div>
        <div>{{mdmjobstatustext}}</div>
        <button :disabled="!selectedSession || !selectedSession.fileTransferJobId || !$store.getters.isAdmin || !linkedDevices.length" @click="getMdmJobStatus">{{(mdmjobstatus && mdmjobstatus.length) ? 'Refresh' : 'Get'}} MDM job status overview</button>   
        <div v-for="status in mdmjobstatus" :key="status.device.id" style="clear:both; padding-top:10px;">
          <table>
              <tr>
                <th>device</th>
                <th>job</th>
                <th>time UTC</th>
                <th>status</th>
                <th>status</th>
              </tr>
              <template v-for="job in status.jobs">
                <template>
                  <tr :key="job.rowId" style="font-size:14px;">
                    <td style="padding-right:10px;">{{status.device.name}}</td>
                    <td style="padding-right:10px;">{{job.jobName}}</td>
                    <td style="padding-right:10px;">{{job.time}} </td>
                    <td style="padding-right:10px;">{{job.status}}</td>
                    <td style="padding-right:10px;"><button v-if="job.jobID == selectedSession.fileTransferJobId" @click="getJobDetails(job)">{{job.subJobDetails ? 'refresh details' : 'details'}}</button>
                      <!-- <div v-for="subjob in job.subJobDetails" :key="subjob.rowId">{{subjob.jobName}}</div> -->
                    </td>
                  </tr>

                  <tr v-for="subjob in job.subJobDetails" :key="subjob.rowId" style="opacity: 0.5; font-size:12px;">
                    <td style="padding-right:10px;"></td>
                    <td style="padding-right:10px;">{{subjob.jobName.replace(blobRoot,"")}}</td>
                    <td style="padding-right:10px;">{{subjob.time}} </td>
                    <td style="padding-right:10px;">{{subjob.status}}</td>
                    <td style="padding-right:10px;"></td>
                  </tr>
                </template>
              </template>
            </table>          
        </div>

        <br>
        <br>
        File list of session:
        <table>
          <tr>
            <th>Storage path</th>
            <th>Size [{{humanFileSize(this.s3files.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0))}}]</th>
            <th>Last Modified UTC</th>
          </tr>
          <tr v-for="file in this.s3files" :key="file.key" >
            <td>
              <span :class="{zip: file.key.toLowerCase().endsWith('.zip') || file.key.toLowerCase().includes('proxy.'), warning: filenameWarning(file.key)}">{{file.key}}</span>
            </td>
            <td>{{humanFileSize(file.size)}}</td>
            <td>{{file.lastModified}}</td>
          </tr>
        </table>
      </div>
            
      <br>
      <br>
      <div>
        <b>Session:</b> <!-- (only fill Id when updating a session, it is generated otherwise) -->
        <input v-model="newSession.Id" type="text" disabled placeholder="session Id (only when updating a session, it is generated otherwise)"> 
        <input v-model="newSession.name" type="text" disabled placeholder="session name (optional)"> 
        <input v-model="newSession.startDate" disabled required type="text" placeholder="start date (yyyy-mm-dd)"> 
        <input v-model="newSession.endDate" disabled required type="text" placeholder="end date (yyyy-mm-dd)"> 
        <textarea v-model="newSession.structure" style="min-height: 100px;" disabled placeholder="json structure"/> 
        <div v-if="testSessionJsonInvalid" style="color: red;">Invalid JSON structure</div>
        <!-- <button @click="addtestsession">Add or Update test session</button> -->
      </div>

      <br>
      <br>
      <div>        
        <b>User list:</b>
        <br>
        <button @click="resetMetadata">Reset Metadata</button><button @click="getMdmDeviceStatus">Get/Refresh MDM status of devices</button>
        <table style="text-align:left;">
          <tr>
            <th>UserId</th>
            <th>First name</th>
            <th>Device</th>
            <th>MDM</th>
            <th>MDM Status</th>
            <th>Metadata</th>            
          </tr>
          <tr v-for="user in users" :key="user.id" style="font-size:14px;">
            <td>{{user.id}}</td>
            <td>{{user.firstName}}</td>
            <td>{{user.device ? `${user.device.id} (${user.device.name})` : '--'}}</td>
            <td>{{user.device ? (user.device.mdmId && user.device.mdmAccount ? ('✔️ ' + user.device.mdmAccount) : '❌') : '--'}}</td>
            <td>{{(user.device && user.device.mdmStatus) ? `${user.device.mdmStatus.connectionStatus} (${user.device.mdmStatus.charging} ${user.device.mdmStatus.battery ? `${user.device.mdmStatus.battery}%` : '--'})` : '--'}}</td>
            <td>{{user.metadata}}</td>
          </tr>    
        </table>    
      </div>

      <br>
      <br>
      <div>
        <b>Session results:</b>
        <br>
        <button @click="getResults">Get session results</button> &nbsp;
        <button @click="exportResults">Export raw segment results</button> &nbsp;
        <button @click="exportFlattenedResults">Export flattened segment results (grouping key required below)</button>
        <input type="text" v-model.trim="externalUserId" placeholder="Type here the external participant metadata key name (like 'pnumber') to use for grouping flattened results..." />
        <br>
        <br>
        <div v-for="answer in this.results" :key="answer.id" >
          {{answer}}
        </div>
      </div>   
      <br>
      <br>   
      <b>Environment:</b> {{env}}
      </div>      

    </div>

    <div class="devicebar">
      <span class="spinner relative" v-if="loading" />
      <div class="counter errordetails" v-if="devicesWithErrors.length">{{devicesWithErrors.length}} devices with errors <a @click="deviceErrorsModal = true">[DETAILS]</a>         
      </div>          
      <div class="counter">{{devicesReady().length}} / {{users.length}} READY
        <div @click="$router.push(`/sessions/${selectedSession.id}/users`)" title="Edit assigned users">👥</div>
      </div>    
      <div v-for="user in orderedUsers" class="devicecard tooltip" :key="user.id" :class="{offline: !user.device || !user.device.timestamp || ((ticker-user.device.timestamp) > $constants.deviceOfflineTimeout), unlinked: !user.device, onhead: user.device && user.device.onhead, technical: user.lastName == 'technical', hasError: user.device && user.device.fileIntegrityList}">
        <div class="condition" v-if="user.condition && selectedSessionIdMap.get(Number(user.condition))">{{ selectedSessionIdMap.get(Number(user.condition)).txt1}}</div>
        {{user.firstName}} 
        <div class="battery" :class="{red: user.device && user.device.battery && user.device.battery <= 20}">
          {{(user.device && user.device.battery) ? user.device.battery : '--'}}% 
          <div class="percentage" v-bind:style="{ transform: 'scaleX('+ ((user.device && user.device.battery) ? user.device.battery : 0)/100.0 + ')' }"></div>
        </div>
        <div class="tooltiptext left" v-if="user.device && user.device.fileIntegrityList && user.currentSessionId == selectedSession.id">          
          <table>
            <tr>
              <th>File</th>
              <th>Reason</th>
            </tr>
            <tr v-for="file in fileIntegrityDetails(user.device.fileIntegrityList)" :key="file.key">
              <td>{{file.key}}</td>
              <td>{{file.reason}}</td>
            </tr>
          </table>          
        </div>
        <div class="error" v-if="user.device && user.device.fileIntegrityList  && user.currentSessionId == selectedSession.id">
          Content error
        </div>
      </div>
    </div>

    <Modal v-if="deviceErrorsModal" @close="deviceErrorsModal = false;" class="deviceerrorsmodal">      
      <h3 slot="header">Devices with content issues</h3>
      <div slot="body">
        <table>
            <tr>
              <th>User</th>
              <th>File</th>
              <th>Reason</th>
            </tr>
            <template v-for="user in devicesWithErrors">          
              <tr v-for="file in fileIntegrityDetails(user.device.fileIntegrityList)" :key="file.key">
                <td>{{user.firstName}}</td>
                <td>{{file.key}}</td>
                <td>{{file.reason}}</td>
              </tr>
            </template>           
        </table> 
      </div>
      <div slot="footer">               
        <button class="insead white" @click="deviceErrorsModal = false">Close</button>              
        <button class="insead" @click="exportDeviceErrors">Download CSV</button>              
      </div>
    </Modal>  

    <Snackbar ref="snackbar" /> 
  </main>
  </div>
</template>

<script>
import { HubConnectionBuilder } from '@microsoft/signalr'
import axios from 'axios'
import Header from '@/components/Header.vue'
import Modal from '@/components/Modal.vue'
import Sidebar from '@/components/Sidebar.vue'
import TreeItem from '@/components/TreeItem.vue'
import Results from '@/components/Results.vue'
import Hotspots from '@/components/Hotspots.vue'
import Heatmap from '@/components/Heatmap.vue'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import vSelect from 'vue-select'
import Snackbar from '@/components/Snackbar.vue'
import { humanFileSize } from '@/utils.js'

const filenameWarningRegex = new RegExp("[^A-Za-z0-9\\-/\\._]+");

export default {
  name: 'Dashboard',
  components: {
    Header,
    TreeItem,
    Modal,
    Results,
    Heatmap,
    Sidebar,
    Hotspots,
    vSelect,
    Snackbar
  },
  data: function(){
    return{
      blobRoot: process.env.VUE_APP_PUBLIC_BLOB,
      connection: undefined,
      users: [],
      results: [],   
      resultsGroups: [],
      resultsSerial: null, 
      ticker: Date.now(),
      selectedSession: undefined,
      updateAvailable: false,
      selectedSessionIdMap: new Map(),
      sessions: [],
      loading: false,
      selectedTreeItem: undefined,
      checkedConditions: [],
      playSegmentModal: false,
      exitSegmentModal: false,
      pauseSessionModal: false,
      archiveResultsModal: false,
      exportResultsModal: false,
      pauseSessionMessage: '',
      selfPacedOnModal: false,
      selfPacedOffModal: false,
      current: { // may go to Vuex store later if needs persistence
        status: undefined,
        segment: undefined,
        conditions: undefined,
        allocation: undefined
      },
      reconnecting: false,
      dashboardUsers: [],
      analyticsTab: 'questions',
      deviceErrorsModal: false,

      // test stuff
      showPlayground: false,
      heatmapItem: undefined,
      segmentHeatmapCondition: undefined,
      s3files: [],
      mdmjobstatustext: undefined,
      mdmjobstatus: [],
      mdmAccount: 'Avris',
      tempPIN: undefined,
      newSession: {
        Id: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
        structure: undefined
      }, 
      testMessage: undefined,
      externalUserId: undefined,
      webhookMessage: undefined,
      activityLog: []
    }
  },
  watch: {
    // whenever selectedTreeItem changes, this function will run
    selectedTreeItem: function (newItem, oldItem) {

      // fine tune default analytics tab shown on load
      this.analyticsTab = 'questions'
      if(!this.questions || !this.questions.length){
        if(this.heatmapVideoItems && this.heatmapVideoItems.length)
          this.analyticsTab = 'heatmap'
        else if(this.hotspots && this.hotspots.length)
          this.analyticsTab = 'hotspots'
      }     

      window.scrollTo({
        top: 0,
        //left: 100,
        behavior: 'smooth'
      });

      if(this.selectedSession?.id && oldItem){        
        const path = `/dashboard/${this.selectedSession.id}` + (newItem?.id ? `/${newItem.id}` : '')
        if(this.$route.path != path)
          this.$router.push(path)
      } 
    },
    "$route.params": async function(newParams, oldParams) {      
      if(newParams.sessionId == oldParams.sessionId)
        this.selectedTreeItem = this.selectedSessionIdMap.get(Number(this.$route.params.treeId ))
      else{
        // await this.getCurrentSession()
        this.selectedSession = this.sessions.find(s => s.id == newParams.sessionId)
        await this.handleSessionSelected()
      }
    }      
  },
  computed: {   
    devicesWithErrors(){
      return this.users.filter(u => u.device?.fileIntegrityList?.length && u.currentSessionId == this.selectedSession.id) 
    },
    linkedDevices(){
      return this.users.filter(u => u.device?.mdmId)
    },
    heatmapVideoItems(){
      if(this.conditions && this.segment?.list?.length) 
        return this.segment.list.find(i => i.id == this.segmentHeatmapCondition?.id)?.segJson?.items?.filter(i => i.type == 'vid' && i.heatmap)
      else
        return this.selectedTreeItem?.segJson?.items?.filter(i => i.type == 'vid' && i.heatmap)
    },
    archiveDisabled(){
      let current = this.resultsGroups.find(g => g.serial == null)
      return current?.from == null && current?.to == null
    },
    contentRoot(){
      return this.blobRoot + (this.selectedSessionStructure.webContentRootPath ?? '')
    },
    testSessionJsonInvalid(){
      try{
        JSON.parse(this.newSession.structure)        
        return false
      }
      catch{
        return true
      }
    },
    env(){
      return process.env.VUE_APP_ENV + ' NODE_ENV: ' + process.env.NODE_ENV
    },
    orderedUsers(){
      return _.orderBy([...this.users], [
          (user) => { return user.device && user.device.timestamp && (this.ticker-user.device.timestamp<this.$constants.deviceOfflineTimeout) },
          (user) => { return user.device && user.device.fileIntegrityList && user.device.fileIntegrityList.length}
        ], ['asc','desc'])

      // return [...this.users].sort((x, y) => {
      //   if ((x.device && x.device.timestamp && (this.ticker-x.device.timestamp<this.$constants.deviceOfflineTimeout)) && (!y.device || !y.device.timestamp || this.ticker-y.device.timestamp>=this.$constants.deviceOfflineTimeout)) {
      //     return -1;
      //   }
      //   if ((y.device && y.device.timestamp && this.ticker-y.device.timestamp<this.$constants.deviceOfflineTimeout) && (!x.device || !x.device.timestamp || this.ticker-x.device.timestamp>=this.$constants.deviceOfflineTimeout)) {
      //     return 1;
      //   }
      //   return 0;
      // });
    },
    overallProgress(){
      if (this.devicesReady(true).length == 0) 
        return 0
      let tmp = this.devicesReady(true).reduce((accumulator, user) => accumulator + (user.progress ?? 0), 0) / this.devicesReady(true).length
      return +parseFloat(tmp).toFixed( 2 )
    },
    selectedSessionStructure(){      
      if(!this.selectedSession)
        return undefined
      //let tmp = JSON.parse((this.selectedSession.custom || !this.selectedSession.contentStructure /* legacy fallback */) ? this.selectedSession.structure : (this.selectedSession.experience?.structure ?? this.selectedSession.contentStructure))
      let tmp = JSON.parse((this.selectedSession.custom || !this.selectedSession.contentStructure /* legacy fallback */) ? (this.selectedSession.experience ?? this.selectedSession).structure : this.selectedSession.contentStructure)
      this.selectedSessionIdMap.clear()   
      this.setTreeParents(tmp)      
      return tmp
    },
    playResourceDisabled(){
      return (this.conditions && !this.conditionsWithRequirements && !this.checkedConditions.length) || (this.current.segment?.id == this.segment?.id)
    },
    conditions(){
      if(!this.selectedTreeItem)
        return undefined
      let tmp =  (this.selectedTreeItem.type == 'conditional' && this.selectedTreeItem.list && this.selectedTreeItem.list.length) 
        ? this.selectedTreeItem.list 
        : (this.selectedTreeItem.parent && this.selectedTreeItem.parent.type && this.selectedTreeItem.parent.type == 'conditional' && this.selectedTreeItem.parent.list && this.selectedTreeItem.parent.list.length
          ? this.selectedTreeItem.parent.list
          : undefined)
      if(tmp){
        tmp[0].parent.condition = 'summary'
        return [tmp[0].parent, ...tmp]
      }
      return undefined
    },
    conditionsWithRequirements(){
      let tmp = this.conditions?.filter(c => c.requirements)
      if(tmp && tmp.length) 
        return tmp
      return null
    },
    segment(){
      if(!this.selectedTreeItem)
        return undefined
      return (this.selectedTreeItem.type == 'seg' || this.selectedTreeItem.type == 'conditional')
        ? (this.selectedTreeItem.parent && this.selectedTreeItem.parent.type == 'conditional')
          ? this.selectedTreeItem.parent
          : this.selectedTreeItem
        : undefined
    },
    questions(){
      if(!this.segment)
        return undefined     

      let items = this.flatItemlist.filter(i => i.Q)

      // same question can be present in each condition within same segment, but we just need to display one of course
      // const arrayUniqueByKey = [...new Map(items.map(item =>[item['id'], item])).values()]
      
      // handle question filtering for condition in Results component, otherwise if same question is inserted into multiple conditions, it would be missing

      let regex = /A[1-9]+/
      items/*arrayUniqueByKey*/.forEach(question => {
        question.options = []
        if(!question.items){
          for (const property in question) {
            //console.log(`${property}: ${object[property]}`);
            if(regex.test(property) && question[property])
              question.options.push(({id: parseInt(property.substring(1)), text: question[property]}))
          }
          if(!question.options.length && question.type == 'rating')
            [1,2,3,4,5].forEach(x => question.options.push(({id: x, text: `Rating ${x.toString()}`})))
        }
        else{
          question.items.forEach(option => {
            question.options.push(({id: option.optionId, text: option.text, textDashboard: option.textDashboard, image: option.image}))
          });
        }
      });

      return items //arrayUniqueByKey
    },
    flatItemlist(){
      if(!this.segment)
        return []
      let items = (this.segment.list && this.segment.list.length)
        ? this.segment.list
            .map(seg => seg.segJson.items.map(i =>  ({ ...i, condition: seg.condition, segmentId: seg.id }))) // store to which condition the item belongs to
            .flat()
        : this.segment.segJson.items.map(i =>  ({ ...i, segmentId: this.segment.id }))
        return items
    },
    hotspots(){      
      let hotspots = this.flatItemlist.filter(i => i.type == 'hotspots')
      return hotspots
    },
    breadcrumbs(){            
      if(!this.selectedTreeItem)
        return []
      let tmp = this.selectedTreeItem      
      let crumbs = [tmp]
      while(tmp.parent){
        tmp = tmp.parent
        crumbs.push(tmp)
      }
      return crumbs.reverse()
    },     
    currentStatusText(){
      return this.current.status == 1 ? 'Playing' : 'undefined status'
    }
  },
  methods:{ 
    humanFileSize(size){
      return humanFileSize(size)
    },
    copyUrl(){
      navigator.clipboard.writeText(window.location)
      this.$refs.snackbar.show('Link copied to clipboard.')
    },
    sessionSearch(options, term){
      const searchTerm = term.toLowerCase();
      return options.filter(o => o.name.toLowerCase().includes(searchTerm) || o.id.toString().toLowerCase().includes(searchTerm))
    },
    async triggerWebsiteRefresh(){
      let resp = await axios.post('experiences/triggerwebsiterefresh')     
      console.log(resp.data)
      this.webhookMessage = `[${resp.data.reasonPhrase}] ${resp.data.isSuccessStatusCode ? 'successfully triggered' : 'oops, something went wrong'} at: ${new Date()}`
    },
    async exportDeviceErrors(){                   
      let csv = "Name,Serial,File,Reason"  
      this.devicesWithErrors.forEach(user => {
        this.fileIntegrityDetails(user.device.fileIntegrityList).forEach(file => {
          csv += `\n${user.firstName},${user.device.id},${file.key},${file.reason}`
        });
      });
      //console.log(csv)
      let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `session-${this.selectedSession.id}-device-issues.csv`);
    },
    devicesReady(includeOffHead){ 
      //console.log(this.ticker)
      return this.users.filter(u => u.device && u.device.timestamp && (includeOffHead || u.device.onhead) && (this.ticker-u.device.timestamp<this.$constants.deviceOfflineTimeout)) 
    },
    // getImgUrl(pic) {
    //   return require('@/assets/' + pic)
    // },
    stripHtml(html){
      return html.replace(/(<([^>]+)>)/gi, "")
    },   
    async FileIntegrity(deviceId, files){
      if(!deviceId) 
        return
           
      let user = await this.tryGetUserWithDevice(deviceId)
      if(user)   {     
        this.$set(user.device, 'fileIntegrityList', files)      
        console.log("file integrity data recieved for device " + deviceId) 
      }
      else
        console.log(`File integrity data received for device with Id ${deviceId} but no user found in this session linked to this device`)                 
    },
    fileIntegrityDetails(files){
      return files.map(f => {
        let s3file = this.s3files.find(s => s.key == f.key)
        let reason = 'unknown'
        if(s3file)
          reason = f.size < 0 ? 'missing' : `${f.size} instead of ${s3file.size} bytes`
        
        return {
          key: f.key,
          reason: reason
        }
      })
    },
    async playSegment(){
      this.current.segment = this.segment
      this.current.status = 1
      if(this.conditionsWithRequirements)
       this.current.conditions = this.conditionsWithRequirements.map(c => ({condition: c.condition, id: c.id, seg: c.seg, txt1: c.txt1, requirements: c.requirements }))
      else
       this.current.conditions = this.conditions ? this.checkedConditions.map(c => ({condition: c.condition, id: c.id, seg: c.seg, txt1: c.txt1 })) : undefined  
      this.current.allocation = 'Automatic'

      this.users.forEach(u => {
        this.$set(u, 'progress', 0)
      });
      
      // call server, so it can notify devices      
      let segmentIds =  this.current.conditions ? this.current.conditions.map(c => ({id: c.id, file: c.seg, requirements: c.requirements})) : [({id: this.segment.id, file: this.segment.seg })]
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/play`, data: segmentIds, method: 'POST' })
      console.log("ALLOCATION: ")
      console.log(resp.data)
      
      this.current.clock = this.selectedSession.clock = resp.data.clock
      // update device cards 
      // TODO conditional content, if current.conditions.length then show condition letter
      //if(this.current.conditions){
        resp.data.allocations.forEach(allocation => {
          let user = this.users.find(u => u.id == allocation.userId)
          //this.$set(user, 'condition', this.current.conditions.find(c => c.id == allocation.condition.split('|')[0]).condition)
          this.$set(user, 'condition', allocation.condition)
        });
      // }
      // else{
      //   this.users.forEach(user => {
      //     this.$set(user, 'condition', undefined)
      //   });
      // }
    },
    async exitSegment(){
      this.current = { // may go to Vuex store later if needs persistence
        status: undefined,
        segment: undefined,
        conditions: undefined,
        allocation: undefined
      }

      // call server, so it can notify devices            
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/stop`, method: 'POST' })     
      console.log(resp.data)
      
      this.current.clock = this.selectedSession.clock = resp.data.clock
      // update device cards 
      // TODO maybe not needed as devices should report when they stopped actually playback, etc...      
      this.users.forEach(user => {
        this.$set(user, 'condition', undefined)
        this.$set(user, 'progress', 0)
      });      
    },
    async pauseSession(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/pause`, data: `"${this.pauseSessionMessage}"`, method: 'POST', headers: {'Content-Type': 'application/json'}})     
      console.log(resp.data)
      /*this.selectedSession.status = */this.current.status = resp.data.status
      /*this.selectedSession.message = */this.current.message = resp.data.message
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async resumeSession(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/resume`, method: 'POST' })     
      console.log(resp.data)
      /*this.selectedSession.status = */this.current.status = resp.data.status
      /*this.selectedSession.message = */this.current.message = resp.data.message
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async selfPacedOn(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/selfpacedon`, method: 'POST' })     
      console.log(resp.data)
      this.current.status = resp.data.status
      this.users.forEach(user => {
        this.$set(user, 'condition', undefined)
      });
      this.current = { // may go to Vuex store later if needs persistence
        status: resp.data.status,
        segment: undefined,
        conditions: undefined,
        allocation: undefined
      }
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    async selfPacedOff(){
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/selfpacedoff`, method: 'POST' })     
      console.log(resp.data)
      this.current.status = resp.data.status
      // this.users.forEach(user => {
      //   this.$set(user, 'condition', undefined)
      // });
      this.current.clock = this.selectedSession.clock = resp.data.clock
    },
    setTreeParents(tree){
      if(tree.type == 'seg' || tree.type == 'list' || tree.type == 'conditional' || tree.condition)
        this.selectedSessionIdMap.set(Number(tree.id), tree)
      if(tree.list && tree.list.length){              
          for(let n in tree.list){
              tree.list[n].parent = tree;
              this.setTreeParents(tree.list[n]);
          }
      }
    },
    treeItemSelected(item){
      if(item != this.selectedTreeItem){
        this.checkedConditions = []        
        this.heatmapItem = undefined
      }        
      this.selectedTreeItem = item  
    },    
    async dashboardConnect(){
        // let resp = await axios({ url: "auth/devicelogin", data: {deviceId: "1", pin: this.tempPIN.toString() }, method: "POST" })
        // console.log("got device token: " + resp.data)

        let temptokenvar = this.$store.state.auth.token;
        let sessionId = this.selectedSession?.id
        this.connection = new HubConnectionBuilder()
          .withUrl(process.env.VUE_APP_API_URL + 'devicehub?sessionId=' + sessionId,{
              accessTokenFactory: () => temptokenvar
          })
          //.configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();    
        
        //console.log("connection ID: " + this.connection.connectionId)

        this.connection.on("Message", function (message) {
          console.log(message);
        }); 
        this.connection.on("DashboardRefresh", this.DashboardRefresh)     
        this.connection.on("DeviceConnected", this.DeviceConnected)
        this.connection.on("DeviceDisconnected", this.DeviceDisconnected)     
        this.connection.on("UserDisconnected", this.UserDisconnected)     
        this.connection.on("UserConnected", this.UserConnected)     
        this.connection.on("UserHeartbeat", this.UserHeartbeat)     
        this.connection.on("AnswerSubmitted", this.AnswerSubmitted)  
        this.connection.on("DeviceUnlinked", this.DeviceUnlinked)
        this.connection.on("SetSessionStatus", this.SetSessionStatus)
        this.connection.on("SessionBroadcast", this.SessionBroadcast)
        this.connection.on("FileIntegrity", this.FileIntegrity)
        this.connection.onreconnecting(this.onReconnecting)
        this.connection.onreconnected(this.onReconnected)

        await this.connection.start() 
    },
    UserConnected(connectionId, name){      
      // add or update connectionIds
      let user = this.dashboardUsers.find(u => u.connectionId == connectionId);
      if(user)
        user.timestamp = Date.now()
      else
        this.dashboardUsers.push({connectionId: connectionId, name: name, timestamp: Date.now() })
      // send heartbeat immediately so newly joined user pick it up
      this.connection.invoke("UserHeartbeat").catch(function (err) {
        console.error(err.toString());
      });
    },
    UserDisconnected(connectionId){
      // remove from connectionIds
      this.dashboardUsers = this.dashboardUsers.filter(u => u.connectionId != connectionId)
    },
    UserHeartbeat(connectionId, name){
      // refresh timestamp for given connectionId
      let user = this.dashboardUsers.find(u => u.connectionId == connectionId);
      if(user)
        user.timestamp = Date.now()
      else
        this.dashboardUsers.push({connectionId: connectionId, name: name, timestamp: Date.now() })
    },
    Heartbeat(){
      // sends heartbeat
      if(this.connection?.connectionState == "Connected") {
        this.connection.invoke("UserHeartbeat").catch(function (err) {
          console.error(err.toString());
        });
      }
      // called in an interval, checks and removes if timeout reached for some users (so in case of missed message), after X seconds user is greyed out, and after Y seconds it gets fully removed      
      this.dashboardUsers = this.dashboardUsers.filter(u => this.ticker-u.timestamp <= this.$constants.userOfflineTimeout*2)
    },
    onReconnecting(){
      console.log('reconnecting')
      this.reconnecting = true
    },
    onReconnected(){
      console.log('reconnected')
      this.reconnecting = false
    },
    async tryGetUserWithDevice(deviceId){
      if(!deviceId)
        return null
      let user = this.users.find(u => u.device && u.device.id == deviceId)
      if(!user){
        // previously unlinked device now sending data -> fetch user-device pairs for session and update user list
        let r = (await axios({ url: `sessions/${this.selectedSession.id}/users?deviceId=${deviceId}` })).data        
        if(r && r.length)
          user = this.users.find(u => !u.device && u.id == r[0].id)
        if(user)
          this.$set(user, 'device', r[0].device)
      }
      return user;
    },
    async AnswerSubmitted(answers){
      console.log("answer(s) submitted:")
      answers.forEach(element => {
        console.log(element)
        this.results.push(element)
      });
    },
    async DeviceConnected(device){
      if(!device) 
        return
      console.log("device connected: " + device.id)
      let user = this.users.find(u => u.id == device.userId) //await this.tryGetUserWithDevice(id)
      if(user){
        this.$set(device, 'timestamp', Date.now())
        this.$set(user, 'device', device)         
      }
      else
        console.log(`Device connected with Id ${device.id} but no user found in this session linked to this device`)
    },
    async DeviceDisconnected(id){
      if(!id) 
        return
      console.log("device disconnected: " + id)
      let user = await this.tryGetUserWithDevice(id)
      if(user)    
        this.$set(user.device, 'timestamp', null)      
      else
        console.log(`Device disconnected with Id ${id} but no user found in this session linked to this device`)
    },
    async DeviceUnlinked(id){
      console.log("device unlinked: " + id)
      if(!id) 
        return
      let user = await this.tryGetUserWithDevice(id)
      if(user)    
        this.$set(user, 'device', null)      
      else
        console.log(`Device unlinked with Id ${id} but no user found in this session linked to this device`)
    },
    async DashboardRefresh(resp){
      let user = await this.tryGetUserWithDevice(resp.id)
      if(user){
        if(resp.status.battery && resp.status.battery > 0)
          this.$set(user.device, 'battery', resp.status.battery)
        this.$set(user.device, 'onhead', resp.status.onHead)        
        if(resp.status.progress || resp.status.progress == 0)
          this.$set(user, 'progress', resp.status.progress)
        this.$set(user.device, 'timestamp', Date.now())
      }
      else
        console.log(`Received info from device with Id ${resp.id} but no user found in this session linked to this device`)
    },
    async SetSessionStatus(newStatus){      
      if(!this.selectedSession)
          return;
      console.log("Session status received: " + newStatus)
      if(this.current.status == newStatus)
        return;
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/users` })       
      this.users.forEach(u => u.condition = resp.data.find(r => r.id == u.id).condition)
      this.selectedSession.status = newStatus
      this.loadCurrentSessionState()  
    },
    async SessionBroadcast(broadcast){      
      if(!this.selectedSession)
          return;
      console.log("Session broadcast received: ")
      console.log(broadcast)
      if(this.current.clock < broadcast.clock)
      {
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/users` })       
        this.users.forEach(u => u.condition = resp.data.find(r => r.id == u.id).condition)
        this.selectedSession.status = broadcast.status
        this.selectedSession.clock = broadcast.clock
        if(broadcast.status == 2){          
          let resp2 = await axios({ url: `sessions/${this.selectedSession.id}` })
          this.current.message = resp2.data[0].message
        }
        else
          this.current.message = null          

        this.loadCurrentSessionState() 
      }
    },
    async getSessions(){
        let resp = await axios({ url: "sessions" })
        //console.log("got sessions: " + resp.data)
        this.sessions = resp.data//.filter(s => s.ownerId == this.$store.getters.userId )
    },
    
    async setCurrentSession(){
      if(!this.selectedSession)
          return
      localStorage.setItem('lastSessionId', this.selectedSession.id)
      let resp = await axios.get(`sessions/${this.selectedSession.id}`)    
      // //console.log("added test sessions: " + resp.data)
      return resp.data[0]
    },
    async getCurrentSession(){
      let sessionId = this.$route.params.sessionId ?? localStorage.getItem('lastSessionId')
      let resp = sessionId ? await axios.get(`sessions/${sessionId}`) : null
      // console.log("got user's current session: ")
      // console.log(resp.data)
      if(resp?.data?.length && this.sessions.find(s => s.id == resp.data[0].id)){        
        //this.selectedSession =         
        //this.selectedSession.editorStructure = JSON.parse(this.selectedSession.structure);
        //this.setTreeParents(this.selectedSession.editorStructure);
        // let tmp = JSON.parse(resp.data.session.structure)
        // this.setTreeParents(tmp)        
        this.selectedSession = resp.data[0]
        this.selectedTreeItem = this.selectedSessionStructure // this computed getter is building the selectedSessionIdMap
        if(this.$route.params.treeId){                       
          setTimeout(() => this.selectedTreeItem = this.selectedSessionIdMap.get(Number(this.$route.params.treeId )), 800)
        }       
                
        // fill values for test session crud
        this.newSession.Id = this.selectedSession.id
        this.newSession.name = this.selectedSession.name
        this.newSession.startDate = this.selectedSession.startDate
        this.newSession.endDate = this.selectedSession.endDate
        this.newSession.structure = this.selectedSession.structure
      }
    },    
    async checkForUpdates(){
      // don't check updates for legacy sessions
      if(!this.selectedSession || this.selectedSession.custom || !this.selectedSession.contentStructure)
          return;
      let resp = await axios.get(`sessions/${this.selectedSession.id}/updates`) 
      if(resp.data && resp.data.length)
        this.updateAvailable = true
    },
    async getUsers(){
      if(!this.selectedSession)
          return;      
      let resp = await axios({ url: `sessions/${this.selectedSession.id}/users` }) 
      //console.log("got users: " + resp.data) 
      this.users = resp.data;      
    },
    sessionSelected(){
      this.$router.push(`/dashboard/${this.selectedSession.id}`)
    },
    async handleSessionSelected(){ 
      if(!this.selectedSession)
        return
      this.loading = true
      this.$nprogress.start()
      //console.log(this.connection)   
      //if(this.connection)   
      this.updateAvailable = false
      await this.connection.stop()      
      let latest = await this.setCurrentSession()
      this.selectedSession.status = latest.status
      this.selectedSession.clock = latest.clock
      this.selectedSession.message = latest.message
      this.selectedTreeItem = this.selectedSessionStructure   
      this.resultsSerial = null
      await this.getUsers()
      this.loadCurrentSessionState()
      await this.checkForUpdates()
      await this.getResults()
      await this.getResultsGroups()
      //if(this.connection) 
      this.connection.connection.baseUrl = process.env.VUE_APP_API_URL + 'devicehub?sessionId=' + this.selectedSession.id
      await this.connection.start()       
      //console.log(this.connection)
      this.heatmapItem = undefined
      this.$nprogress.done()
      this.loading = false


      // fill values for test session crud
      this.newSession.Id = this.selectedSession.id
      this.newSession.name = this.selectedSession.name
      this.newSession.startDate = this.selectedSession.startDate
      this.newSession.endDate = this.selectedSession.endDate
      this.newSession.structure = this.selectedSession.structure
      this.activityLog = []
    },
    loadCurrentSessionState(){
      if(!this.selectedSession)
        return 
      let allocatedConditions = [...new Set(this.users.map(u => u.condition).filter(c => c))]
      //debugger
      this.current.conditions = allocatedConditions.length       
        ? this.current.conditions = allocatedConditions.map(c => ({condition: this.selectedSessionIdMap.get(Number(c))?.condition, id: c, txt1: this.selectedSessionIdMap.get(Number(c))?.txt1 }))      
        : undefined

      const staleIds = allocatedConditions.filter(c => !this.selectedSessionIdMap.get(Number(c)))
      if(staleIds.length)
        this.$refs.snackbar.show(`WARNING: Cannot find previously allocated segment/conditions: ${staleIds.join(',')}. The session was probably edited while running.`, 10000)
      
      if(allocatedConditions.length)      
        this.current.segment = (allocatedConditions.length > 1)
          ? this.selectedSessionIdMap.get(Number(allocatedConditions[0]))?.parent
          : this.selectedSessionIdMap.get(Number(allocatedConditions[0]))
      else
        this.current.segment = undefined

      this.current.status = this.selectedSession.status
      this.current.clock = this.selectedSession.clock      
      this.current.allocation = 'Automatic'

      this.checkedConditions = [];

      try{
        //if(this.selectedSession.files)
          //this.s3files = JSON.parse(this.selectedSession.files)
          this.s3files = this.selectedSession.fileList ?? []
        //else
          //this.s3files = []
        
      }
      // eslint-disable-next-line
      catch{
        this.s3files = []
      }
    },
    async getResults(){
      if(!this.selectedSession)
        return      
      
      this.loading = true
      this.$nprogress.start()
      
      let query = ''  
      switch (this.resultsSerial) {
        case "-1":
          query = ''
          break;
        case null:
          query = '?archived=false'
          break;      
        default:
          query = `?serial=${this.resultsSerial}`
          break;
      }   

      let resp = await axios({ url: `sessions/${this.selectedSession.id}/results${query}` }) 
      this.results = resp.data

      this.loading = false
      this.$nprogress.done()
      
    },
    async resultsGroupSelected(){
      await this.getResults()
    },
    async getResultsGroups(){
      if(!this.selectedSession)
        return 
      let resp = await axios.get( `sessions/${this.selectedSession.id}/resultsgroups`)
      this.resultsGroups = resp.data
    },
    async archiveResults(){
      if(!this.selectedSession)
        return      
      await axios({ url: `sessions/${this.selectedSession.id}/archiveresults`, method: "POST" })   
      this.results = []  
      //this.heatmapItemId = null      
      await this.getResultsGroups()
    },
    async exportResults(){
      if(!this.selectedSession)
        return               
      this.$refs.results.export(`session${this.selectedSession.id}-segment${this.segment.id}-results-export_test`)
    },
    async exportFlattenedResults(){
      if(!this.selectedSession)
        return               
      this.$refs.results.export2_1(`session${this.selectedSession.id}-segment${this.segment.id}-results-export_grouped`, this.externalUserId)
    },
    presentResults(){
      localStorage.setItem("presentation", JSON.stringify({
        contentRoot: this.contentRoot, 
        questions: this.questions, 
        results: this.results, 
        conditions: this.conditions?.map(c => ({condition: c.condition, txt1: c.txt1, id: c.id})),
        hotspots: this.hotspots,
        hotspotsLabelName: this.hotspotsLabelName,
        segmentId: (this.segment && this.conditions && this.segmentHeatmapCondition) ? this.segmentHeatmapCondition.id : (this.segment ? this.segment.id : undefined)  // copied from template but this whole presentation module shall be rewritten
      }))  
      window.open('/presentation', 'presentationWindow', 'width=800, height=600')
    },
    async resetMetadata(){
      if(!this.selectedSession)
        return      
      if(confirm('Reset all metadata of each user for the session?')){
        await axios({ url: `sessions/${this.selectedSession.id}/metadata`, method: "DELETE" })
        await this.getUsers()
      }
    },    
    closeDropdown: function(e){
      if(!this.$refs.conditionsdropdown)
        return
      const withinBoundaries = e.composedPath().includes(this.$refs.conditionsdropdown)      
      if(this.$refs.conditionsdropdown.getAttribute('opened') != null && (e.keyCode == 27 || (e.keyCode == undefined && !withinBoundaries)))
        this.$refs.conditionsdropdown.removeAttribute('opened')
    },

    // TEST METHODS
    async getS3files(){
      try{
        this.mdmjobstatustext = undefined
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/files` }) 
        this.s3files = resp.data.fileList
        this.selectedSession.fileListDate = resp.data.fileListDate
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    },
    filenameWarning(filename){
      return filenameWarningRegex.test(filename)
    },
    async getLogs(){
      let resp = await axios.get(`sessions/${this.selectedSession.id}/activity`)
      this.activityLog = resp.data
    },
    async applyFileTransferJob(){
      try{
        this.mdmjobstatustext = undefined
        /* let resp = */await axios({ url: `sessions/${this.selectedSession.id}/applymdmjob?account=${this.mdmAccount}&job=filetransfer`, method: "POST" }) 
        this.mdmjobstatustext = 'file transfer job applied (and now we wait..)'        
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    },  
      
    async applyEmptyFolderJob(){
      try{
        this.mdmjobstatustext = undefined
        /* let resp = */await axios({ url: `sessions/${this.selectedSession.id}/applymdmjob?account=${this.mdmAccount}&job=emptyfolder`, method: "POST" }) 
        this.mdmjobstatustext = 'empty folder job applied (and now we wait..)'        
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    }, 
    async getMdmJobStatus(){
      try{
        this.mdmjobstatustext = undefined
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/mdmjobstatus?account=${this.mdmAccount}`, method: "GET" }) 
        this.mdmjobstatustext = 'fetching mdm job status successfull'  
        this.mdmjobstatus = resp.data
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    },
    async getJobDetails(job){
      try{
        this.mdmjobstatustext = undefined
        // 42gears mdm api casing for jobId is JobID with capital "ID"... for the rest casing is as usual.
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/mdmsubjobstatus?account=${this.mdmAccount}&jobId=${job.jobID}&deviceId=${job.deviceIdJobQueue}&rowId=${job.rowId}`, method: "GET" }) 
        this.mdmjobstatustext = 'fetching mdm subjob status successfull'  
        this.$set(job, 'subJobDetails', resp.data)
        if(job.subJobDetails.every(j => j.status == 'DEPLOYED'))
          job.status = 'DEPLOYED'
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    },    
    async getMdmDeviceStatus(){
      try{
        this.mdmjobstatustext = undefined
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/mdmdevicestatus?account=${this.mdmAccount}`, method: "GET" }) 
        this.mdmjobstatustext = 'fetching mdm device status successfull'  
        for (let i = 0; i < resp.data.length; i++) {
          let status = resp.data[i]
          let user = this.users.find(u => u.device && u.device.mdmId == status.mdmId)
          if(user)
            this.$set(user.device, 'mdmStatus', status)
        }            
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    },
    async createMDMjob(){
      try{
        this.mdmjobstatustext = undefined
        let resp = await axios({ url: `sessions/${this.selectedSession.id}/createmdmjob?account=${this.mdmAccount}`, method: "POST" }) 
        this.mdmjobstatustext = `mdm job created with name "${resp.data.jobName}"`
        this.selectedSession.fileList = resp.data.session.fileList
        this.selectedSession.fileListDate = resp.data.session.fileListDate
        this.selectedSession.fileTransferJobId = resp.data.session.fileTransferJobId
        this.selectedSession.fileTransferJobCreated = resp.data.session.fileTransferJobCreated
        this.selectedSession.fileTransferJobApplied = resp.data.session.fileTransferJobApplied
      }
      catch(err){
        this.mdmjobstatustext = err?.response?.data ?? err
      }
    },
    signalrtest(){
        this.connection.invoke("SendTestMessageToAll", this.testMessage).catch(function (err) {
          return console.error(err.toString());
        });
    },
    async otptest(){
        let resp = await axios({ url: "auth/generatedeviceotp", method: "POST" })
        console.log("got fancy otp PIN: " + resp.data)
        this.tempPIN = resp.data
        alert(this.tempPIN)
    },    
    async addtestsession(){
      if(this.newSession.Id && this.current.status && this.current.status != 0 && !window.confirm('WARNING: Modifying a session in active state is not recommended! Are you sure?'))
        return
           
      let resp = await axios({ url: "sessions" + (this.newSession.Id ? `/${this.newSession.Id}` : ''), data: this.newSession, method: "POST" })    
      console.log("added/updated test sessions: " + resp.data)      
    }    
  },
  async created(){    
    this.tickerInterval = setInterval(() => {this.ticker = Date.now()}, 5000)   
    this.heartbeatInterval = setInterval(() => {this.Heartbeat()}, 15000)   
    window.addEventListener('beforeunload', function () {
        localStorage.removeItem("presentation")
    }) 
  },
  async mounted(){
    this.loading = true
    this.$nprogress.start()
    await this.getSessions()
    await this.getCurrentSession()
    await this.getUsers()
    this.loadCurrentSessionState()
    await this.checkForUpdates()
    await this.getResults()
    await this.getResultsGroups()
    await this.dashboardConnect()
    window.addEventListener("keyup", this.closeDropdown, false);
    window.addEventListener("click", this.closeDropdown, false);
    this.$nprogress.done()
    this.loading = false        
  },
  async beforeDestroy() {
    await this.connection.stop()  
    window.removeEventListener("keyup", this.closeDropdown, false);
    window.removeEventListener("click", this.closeDropdown, false); 
    localStorage.removeItem("presentation")
    if(this.tickerInterval)
      clearInterval(this.tickerInterval)
    if(this.heartbeatInterval)
      clearInterval(this.heartbeatInterval)
  }
}
</script>


<style lang="scss">

.reconnecting{
  position: fixed;
  top: 0;
  left: 40%;
  right: 40%;
  text-align: center;
  z-index: 1000;
  padding: 4px;
  background-color: red;
  border-radius: $borderradius;
  color: white;
}

.modal-container{
  width: 600px;
}
.modal-footer{
  padding-top: 35px;

  > div{
    display: flex;
    justify-content: space-between;
  }

  button.insead{
    width: 270px;    
  }
}

.modal-body{
  .title{
    margin-top: 30px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $october;

    .charCount{
      float: right;
      color: #9999AA;
    }
  }

  textarea{
    resize: none;
    height: 80px;
    margin-top: 10px;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    color: #545469;
  }
}


.statusbar{
  position: fixed;
  top: 80px;
  //margin-top:80px;
  width:100%;
  left: 0;
  right: 0;
  //height: 90px;
  box-shadow: $shadowS;
  border-bottom: $bordermixin;  
  background-color: white;
  z-index: 2;
  
  .session-paused, .self-paced{
    // position: fixed;
    // top: 80px;
    // left: 0;
    // right: 0;
    height: 90px;
    //box-shadow: 0px 4px 4px rgb(60 68 72 / 3%);
    //border-bottom: 1px solid #DFE6EA;
    //padding: 0 25px;
    background-color: $green1;
    z-index: 3;
    display: flex;
    color: white;
    line-height: 90px;
    box-shadow: $shadowS;
    text-align: center;

    .status{
      min-width: 200px;      
      box-shadow: $shadowS;
      text-transform: uppercase;    
      font-weight: bold;
      font-size: 12px;
      //line-height: 14px;
      /* identical to box height */
      letter-spacing: 0.05em;      

      &.orange{
        background: #F28B42;
      }

      span.pause-icon{
        width: 32px;
        height: 32px;
        vertical-align: middle;
        background-image: url("../assets/pause-icon-white.svg");
        background-repeat: no-repeat;
        margin-right: 10px;
        display: inline-block;
      }
    }

    .info{
      width: 100%;   

      span.message{
        margin-right: 30px;
      }
    }
  }

  .self-paced{
    background-color: #009F6E;
    .status{
      min-width: 160px;
    }
  }

  .session-status{
    height: 90px;
    padding: 0 25px;

    .thumbnail{
      padding-top: 18px;
      margin-right: 20px;

      img {
        max-height: 54px;
        border-radius: $borderradius;
      }
    }

    div.conditions{
      padding-left: 0;

      span.spacer:after{
        padding: 0 3px;
      }
    }

    .control{
      padding-top: 28px;

      button{
        border: $bordermixin;
        border-radius: 16px;
        width: 32px;
        height: 32px;
        box-shadow: 0px 4px 4px rgba(60, 68, 72, 0.03);
        outline: none;

        &.pause{
          background-color: white;
          background-image: url("../assets/pause-icon.svg");
          background-repeat: no-repeat;
          background-position: -5px -1px;

          &:hover{
            border-color: $green1;
            box-shadow: $shadowM;

            &:active{
              border-color: $green2;
            }
          }
        }

        &.play{
          background-image: url("../assets/play-icon.svg");
          background-repeat: no-repeat;
          background-size: cover;
          border: none;

          &:hover{
            opacity: 0.75;
            // border: 1px solid $green2;
            box-shadow: $shadowM;

            // &:active{
            //   border-color: $green2;
            // }
          }
        }
      }
    }

    .progressbar{
      width: 192px;
      height: 6px;
      background: #DFE6EA;
      border-radius: 100px;
      margin-top: 15px; // with play-pause button 10px;

      .progress{
        height: 6px;
        border-radius: 100px;
        background-color: $green2;
      }
    }

    > button.insead {
      // float:right;
      margin: 25px 0 0 12px;
    }

    span.help{
      color: $july;
      font-weight: bold;
      font-size: 10px;
      vertical-align: bottom;
      background-color: #DFE6EA;
      border-radius: 100px;
      display: inline-block;
      width: 14px;
      height: 14px;
      text-align: center;
      margin-left: 6px;
      cursor: help;
    }

    > div{
      float: left;
      margin-right: 30px;
      font-size: 13px;
      line-height: 18px;
      color: $december;

      .title{
        margin: 25px 0 7px 0;
        text-transform: uppercase;
        color: $green1;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
      }
    }
  }
}

main.sticky{
  margin-top: 80px;
  &.extended{
    margin-top: 170px;
  }
}

.sidebar{
  background-color: #F8F9FB;

  .tree{
    margin-top: 0;
  }
}

.breadcrumbs{
  margin: 5px 0 10px 0;
  padding-right: 30px;
  position: relative;

  span.copy{
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.5;
    &:hover{
      opacity: 1;
      transform: scale(1.1);
    }
  }  

  span.crumb{
    padding: 0 7px;
    cursor: pointer;

    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      color: $green1;
    }
  }
}

.segment{
  border: $bordermixin;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  display: flex;

  .image{
    max-height: 185px;
    margin: 20px;

    img {
      max-height: 185px;
    }
  }

  .info{
    margin: 20px;
    font-size: 15px;
    line-height: 18px;
    color: $december;

    .text{
      clear: both;  
      
      .previewbtntooltip{
        font-size: 13px;
        color: $july;
        padding-left: 12px;
      }
    }
  }  
}

.segment-controls{
  padding: 20px;
  border: $bordermixin;
  border-radius: 0 0 3px 3px;
  border-top: none;
  overflow: visible;

  .control{
    float: left;  
    margin-right: 20px;  

    .title{
      font-size: 12px;
      line-height: 14px;
      min-height: 14px;
      color: $october;
      font-weight: bold;
      margin-bottom: 12px;
      letter-spacing: 0.05em;
    }
  }

  .button{
    position: relative;
  }  
}

.segment-controls .dropdown{
  min-width: 250px;
}

.heatmapconditions{
  overflow: hidden;
  border-bottom: $bordermixin;
  background-color: white;
  padding: 20px 20px 0 20px;
  margin: 15px auto 15px auto;
  border: 1px solid #DFE6EA;
  border-radius: 3px;

  .condition{
    margin-bottom: 0;
    padding-bottom: 14px;
    float: left;
    margin-right: 22px;
    padding-right: 1px;
    color: #80878B;
    cursor: pointer;

    &.active{
      color: #00684B;
      border-bottom: 2px solid #00684B;

      span.letter{
        background-color: #E9F4EA;
      }
    }

    &:hover{
      color: #00684B;
      span.letter{
        background-color: #E9F4EA;
      }
    }

    span.letter{
      border-radius: 5px;
      background-color: #EFF2F4;
      padding: 2px 5px;
      //margin-right: 6px;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;       
    }
  }    
}


.segment-controls .dropdown, .statusbar .conditions{
    padding-left: 12px;

    span.spacer{
      &:after{
        content: '+';
        padding: 0 10px;
      }
      &:last-of-type{
        //padding: 0;

        &:after{
          content: '';
          display: none;
        }
      }
    }

    span.letter{
      border-radius: 5px;
      background-color: #E9F4EA; 
      color: $green1;
      padding: 2px 5px;  
      //margin-right: 10px;  
      font-weight: bold;   
      font-size: 12px;
      line-height: 14px;      
    }
  }

.folders{
  //display: flex;
  margin-left: -12px;
  margin-right: -12px;

  .folder{
    width: 286px;
    height: 296px;
    flex: none;
    float: left;
    margin: 15px 10px;
    background-color: white;
    cursor: pointer;
    border: $bordermixin;
    border-radius: $borderradius;    
    transition: border-color 0.2s;
    overflow: hidden;
    
    &:hover{
      border: 1px solid #57af8073;      
      transition: border-color 0.2s;
      box-shadow: $shadowS;
    }

    .preview{
      //width: 286px;
      max-width: 286px;
      height: 208px;
      background-color: #E9F4EA;
      background-image: url("../assets/folder.svg");
      background-size: 70px 62px;
      background-repeat: no-repeat;
      background-position: center center;
      //border-radius: 3px 3px 0 0;

      &.hasimage{
        background-size: cover;
      }
    }

    .info{
      padding: 24px 16px 10px 16px;
      height: 88px;

      .number{
        min-width: 37px;
        height: 32px;
        float: left;
        border-radius: $borderradius;
        background-color: #4F2F6C;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: white;
        padding: 8px 5px;
        margin-top: 4px;
      }

      .title{
        font-size: 16px;
        line-height: 20px;
        color: black;        
        /* no need as long as we don't have the number left to it (like "1.1" in purple)
          padding-left: 40px;
          margin-left: 10px; 
        */
        
        span{
          font-size: 12px;
          line-height: 14px;
          color: #545469;
        }
      }
    }
  }
}

.session-controls{
  .name{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $green1;
    margin: 32px 25px 0 10px;
    float: left;

    span{
      color: $october;
      font-weight: normal;
    }
  }

  button {
    float: left;
    margin: 21px 0 0 12px;
    //font-weight: bold;    
    &:hover{      
      &:active{
        color: $green2;
        border-color: $green2; 
      }  
    }
  }

}

.deviceerrorsmodal .modal-container{
  width: 80%;
  table{
    width: 100%;
    th{
      text-align: left;
    }
  }
}

.devicebar{
    width: 250px;
    min-width: 250px;
    padding: 20px 17px;
    font-weight: bold;
    font-size: 12px;
    position: relative;

    .spinner{
      top: 15px;
    }

    .counter{
      padding-bottom: 5px;
      position: relative;

      > div{
        position: absolute;
        right: 0;
        top: -6px;
        cursor: pointer;
        opacity: 0.5;
        font-size: 16px;
        &:hover{
          opacity: 1;
          transform: scale(1.1);
        }
      }

      &.errordetails{
        margin-bottom: 10px;
        border-bottom: 1px dashed #cecece;
      }

      a{
        font-size: 13px;
      }
    }

    .devicecard{
      border: $bordermixin;
      box-shadow: $shadowM;
      padding: 18px 12px 16px 50px;
      margin: 8px 0 8px 0;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      background-image: url("../assets/headset-icon-orange.svg");
      background-size: 26px 20px;
      background-repeat: no-repeat;
      background-position-x: 12px;
      background-position-y: 16px;
      transition: opacity 0.5s ease;
      position: relative;

      .condition{
        text-align: center;
        position: absolute;
        width: 26px;
        left: 12px;
        line-height: 20px;
        font-size: 11px;
        font-weight: bold;
        color: white;
      }

      &.hasError{
        padding-bottom: 10px;
        cursor: help;

        .tooltiptext{
          position: fixed;
          right: 265px;
          top: 100px;
          bottom: unset;
          left: unset;
          transform: none;
          text-align: left;
        }
      }
      .error{
        color: red;
        font-weight: bold;
        font-size:12px;
        //margin-top: 2px;
      }

      &.offline{
        opacity: 0.5;
        transition: opacity 0.5s ease;
      }

      &.onhead{
        background-image: url("../assets/headset-icon.svg");
      }

      &.unlinked{
        background-image: url("../assets/headset-unlinked-icon.svg");
      }      

      .battery{
        float: right;        
        background-image: url("../assets/battery-frame.svg");
        background-size: 19px 10px;
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 23px;     
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;   
        color: $green1;
        position: relative;

        .percentage{
          height: 6px;
          width: 14px;
          background-color: $green2;
          position: absolute;
          right: 3px;
          top: 5px;
          transform-origin: left;
          transform: scaleX(0.6);
        }

        &.red{
          color: $red;
          background-image: url("../assets/battery-frame-red.svg");

          .percentage{
            background-color: $red;
          }
        }
      }
    }
}

.sidebar{
  .title{  
    font-weight: bold;
    font-size: 12px;
    padding: 7px 25px 12px 25px;
    letter-spacing: 0.05em;  
    position: relative;

    > span{      
      font-size: 16px;
      margin-left: 10px;
      display: inline-block;
      cursor: pointer;
      opacity: 0.5;
      &:hover{
        opacity: 1;
        transform: scale(1.1);
      }
    } 
    
    .update{
      font-size: 14px;
      color: $red;
      padding-left: 0px;
      b{
        font-size:20px;
      }
    }
  }
  .sessionlist{
    padding: 25px;
    padding-bottom: 0;
    //min-width: 300px;

    .v-select{ 
      .vs__dropdown-menu{
        width: auto;
      }
      .vs__selected-options {
        flex-wrap: nowrap;        
        overflow: hidden;
        white-space: nowrap;
        min-width: 170px;
      }
    }

    .title{
      padding: 0 0 12px 0;
    }

    .select-css{
      max-width: 200px;
    }
  }
} 

.segment-results{
  margin-top: 30px;  

  .timerangedropdown{
    width: auto;
    float: left;
  }

  .heatmapvideoitem{
    color: $green2;
    cursor: pointer;
    &:before{
      width: 20px;
      display: inline-block;
      content: '📼';
      //content: '🎞';
    }
    &:hover{
      color:$green1;      
    }
    &.selected{
      font-weight: bold;
      &:before{
        content: '🎬'
        //font-size: 22px;
      }
    }
  }

  span.title{    
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
    float: left;
    padding-top: 7px;
    color: $july;
    cursor: pointer;
    border-bottom: $bordermixin;
    padding-bottom: 1px;

    &:hover{
      color: $green1;
    }
    
    &:last-of-type{
      margin-right: 20px;
    }

    &.active{
      color: $green1;
      border-bottom: 2px solid $green1;
      padding-bottom: 0;
    }
  }

  // .container{
  //   margin-top: 15px;
  //   border: $bordermixin;
  //   border-radius: $borderradius;
  // }
}

.playground{  
  
  .mdm{
    th{
      text-align: left;
    }
    .zip{
      color: rgb(196, 196, 196);
    }
    .warning{
      color: orange;
      &:after{
        content: ' [filename contains whitespaces or special chars other than _ and -]';
        color: rgb(196, 196, 196);
      }
    }
  }
}

</style>
