<template>
  <div class="container">
    
    <!-- <br> -->
    player size: <input type="number" style="width:70px;" v-model.number="scale" />
    sample rate: <input type="number" style="width:70px;" v-model="sampleRate" />    
    <!-- <br> -->
    sliding window samples: <input type="number" style="width:70px;" v-model.number="slidingWindowSamples" /> ({{Math.round(slidingWindowSamples/sampleRate * 1e3) / 1e3}} seconds)
    <br>draw heatmap: <input type="checkbox" v-model="drawHeatmap"> Data scale - 
    min: <input type="number" style="width: 70px;" v-model.number="dataMin" @input="setScale" />
    max: <input type="number" style="width: 70px;" v-model.number="dataMax" @input="setScale" />
    radius: <input type="number" style="width:70px;" v-model.number="radius" /> 
    <br>draw gaze points: <input type="checkbox" v-model="drawGazePoints">
    <br>draw motion trail:<input type="checkbox" v-model="drawMotionTrail">
    length: <input type="number" style="width:70px;" v-model.number="motionTrailLength" />    
    radius: <input type="number" style="width:70px;" v-model.number="motionTrailRadius" />   
    <br>
    <br>
    <button @click="loadRecordings(true)" class="insead autowidth" :disabled="!sessionId || !segmentId || !item || loading">
      Load recordings for selected video
    </button> 
    <template v-if="item">
    {{recordings.length}}/{{recordingList.length}} loaded
    </template>
    <template v-else>
      Select a video above
    </template>
    <button class="insead white autowidth" :disabled="!recordingList || !recordingList.length" @click="showRecordingList = !showRecordingList">
      {{showRecordingList ? '▲ Hide' : '▼ Show'}} list
    </button>
    <button class="insead white autowidth" style="margin-left: 10px;" :disabled="!recordings || !recordings.length" @click="exportRawDataCsv(false)">Copy CSV</button>
    <button class="insead white autowidth" style="margin-left: 10px;" :disabled="!recordings || !recordings.length" @click="exportRawDataCsv(true)">Export CSV</button>
    <div v-show="showRecordingList" class="recordinglist">
      <br>
      <table>
        <tr>
          <th><input type="checkbox" v-model="allSelected" @click="allSelectedChanged" :disabled="!recordings || !recordings.length"></th>
          <th>Loaded</th>
          <th>Id</th>
          <th>Sample rate</th>
          <th>Size</th>
          <th>UserId</th>
          <th>Archived</th>
          <th>Metadata</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
        <tr v-for="rec in recordingList" :key="rec.id">
          <td><input type="checkbox" :value="rec.id" v-model="rec.checked" :disabled="!(rec.x && rec.x.length && rec.y && rec.y.length)"></td>
          <td>{{(rec.x && rec.x.length && rec.y && rec.y.length) ? 'yes' : '-'}}</td>
          <td>{{rec.id}}</td>
          <td>{{rec.sampleRate}}/s</td>
          <td>{{(rec.size/1024).toFixed(1)}}KB</td>
          <td>{{rec.userId}}</td>
          <td>{{rec.archived}}</td>
          <td>{{rec.metadata}}</td>
          <td>{{new Date(rec.date).toLocaleString()}}</td>
          <td><button :disabled="!(rec.x && rec.x.length && rec.y && rec.y.length)" @click="recordingToClipboard(rec)">Copy CSV</button></td>
        </tr>
      </table>
    </div>
    <br>

    <div class="heatmapcontainer">
      <media-controller class="mediacontroller" :style="{width: videoWidth+'px', height: videoHeight+44+'px'}">
        <video slot="media" ref="video" :src="videoSource" :width="videoWidth" id="video" @loadedmetadata="videoMetadataLoaded">
          <!-- <source src="../assets/demovideo2.mp4" type="video/mp4"> -->
          <!-- <source src="../assets/BC_LR_01.mp4" type="video/mp4"> -->
          <!-- <source src="https://s3.eu-central-1.amazonaws.com/dev.public.avris.io/balint-test1/BC_LR_01.mp4" type="video/mp4"> -->
          Sorry, your browser doesn't support embedded videos.
        </video>
        <media-control-bar>
          <media-play-button></media-play-button>
          <!-- <media-mute-button></media-mute-button>
          <media-volume-range></media-volume-range> -->
          <media-time-range ref="mediatimerange"></media-time-range> 
          <media-seek-backward-button/>
          <media-seek-forward-button/>
          <media-time-display show-duration></media-time-display>    
        </media-control-bar>
      </media-controller>

      <div class="heatmap" ref="heatmap" :style="{width: videoWidth+'px', height: videoHeight+'px', visibility: drawHeatmap ? 'visible' : 'hidden'}">      
      </div>

      <canvas id="motionTrailCanvas" ref="motionTrailCanvas" :width="videoWidth" :height="videoHeight" />

      <div class="areas" ref="areas" :style="{width: videoWidth+'px', height: videoHeight+'px', visibility: !hideAreas ? 'visible' : 'hidden'}">
        <vue-draggable-resizable v-for="(area, index) in areas" :key="area.label" 
          class="area" 
          :w="area.w" 
          :h="area.h" 
          :x="area.x" 
          :y="area.y" 
          @dragging="onAreaDrag" 
          @resizing="onAreaResize" 
          @resizestop="recalcArea"
          @dragstop="recalcArea"
          :parent="true"
          :data-areaindex="index"
        >
          <span class="label">{{area.label}}</span>        
          <span v-if="labelPercentages" class="label">{{area.liveCoverage ? area.liveCoverage.toFixed(1) : '-'}}%</span>  
          <br v-if="labelPercentagesOverall">      
          <span v-if="labelPercentagesOverall" class="label">Overall {{area.aggregatedCoverage ? area.aggregatedCoverage.toFixed(1) : '-'}}%</span>        
        </vue-draggable-resizable>
      </div>

      <div class="sections" ref="sections" v-if="sections || markers">
        <div v-for="section in sections" class="section" :key="section.title" :style="{width: section.width+'%', left: section.left+'%'}">
          {{section.title}}
        </div>
        <div v-for="marker in markers" class="marker" :key="marker.title" :style="{left: marker.left+'%'}" @click="$refs.video.currentTime = marker.at-2">
          <span>{{marker.title}}</span>
        </div>
      </div>
    </div>

    <div class="arealist">
      <!-- <button @click="debug">debug</button> -->
      <button class="insead autowidth white left" @click="loadAreas" :disabled="!sessionId || !segmentId || !item || loading">Load areas</button>      
      <button class="insead autowidth white left" @click="saveAreas" :disabled="!sessionId || !segmentId || !item || loading">Save areas</button>      
      <button class="insead autowidth white left" @click="addArea" :disabled="loading">Add area</button>      
      <button class="insead autowidth white left" @click="aggregateAreaCoverage" :disabled="!recordings || !recordings.length || loading">Calc overall</button>
      <button class="insead autowidth white left" @click="areasToClipboard(false)" :disabled="!areas || !areas.length || loading">Copy CSV</button>
      <button class="insead autowidth white left" @click="areasToClipboard(true)" :disabled="!areas || !areas.length || loading">Export CSV</button>
      <br style="clear:both;">
      <input type="checkbox" v-model="hideAreas" /> hide areas
      <input type="checkbox" v-model="labelPercentages" /> live labels
      <input type="checkbox" v-model="labelPercentagesOverall" /> overall labels
      <br>
      <br>
      <div v-for="(area, index) in areas" :key="index">
        <input type="text" v-model.trim="area.label">
        <button @click="removeArea(index)">delete</button>
        current {{area.liveCoverage ? area.liveCoverage.toFixed(1) : '-'}}%, overall: {{area.aggregatedCoverage ? area.aggregatedCoverage.toFixed(1) : '-'}}%
      </div>
      <br>
      <!-- <span style="font-size:12px; color: grey;"><b>Note:</b> in this demo, the area of the heatmap (green) and the video differs, for accurate numbers this will require custom video controls to be implemented outside the default video player, so the heatmap will not cover the controls.</span> -->
    </div>

    <div class="userlist">    
      <b>Time spent in areas per user:  </b>
      <button class="insead autowidth white" @click="areasPerUserToClipboard(false)" :disabled="!areas || !areas.length || loading">Copy CSV</button>
      <button class="insead autowidth white" @click="areasPerUserToClipboard(true)" :disabled="!areas || !areas.length || loading" style="margin-left: 10px;">Export CSV</button>
      <br>  
      <br>  
      <!-- <div v-for="(rec, index) in recordings" :key="index">
        {{rec.userId}} metadata: (<span v-for="meta in rec.metadata" :key="meta.key">{{meta.key}}:{{meta.value}},</span>)
        <br>
        <div v-for="(area, index2) in areas" :key="'a'+index2">
          {{area.label}} {{((rec.areaSamples ? rec.areaSamples[index2] : 0)/rec.x.length*100).toFixed(2)}}%, 
        </div>
        <br>        
      </div> -->
      <div v-for="item in areasPerUser" :key="item.userId">
        {{item.userId}}
         - metadata: {{item.metadata}}
        <br>
        <div v-for="a in item.areas" :key="a.label">
          {{a.label}} {{a.coverage.toFixed(2)}}%
        </div>
        <br>        
      </div>
    </div>

    <Snackbar ref="snackbar" />
  </div>
</template>



<script>
import h337 from 'heatmap.js'
import VueDraggableResizable from 'vue-draggable-resizable'
import Snackbar from '@/components/Snackbar.vue';
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import 'media-chrome'
import axios from 'axios'
import _ from 'lodash'
import { saveAs } from 'file-saver'

//let motionPositions = []
let motionPositions = []
let slidingWindowDataPoints = []
let localThis
let sample = 0
let lastSample
let failsafe
let sampleInterval

export default {
  name: 'Heatmap',
  components: {
    VueDraggableResizable,
    Snackbar
  },
  props: {
    sessionId: undefined,
    segmentId: undefined,
    item: undefined,
    serial: undefined,
    contentRoot: undefined
  },
  data: function() {
    return {           
      recordings: [],
      recordingList: [],
      allSelected: true,
      areas :[],
      dataMin: 0,
      dataMax: 10,
      radius: 40,
      scale: 0,
      baseWidth: 600,
      baseHeight: 300,
      videoDuration: undefined,
      heatmapInstance: undefined,
      sampleRate: 10,
      recorderInterval: undefined,
      slidingWindow: true,
      slidingWindowSamples: 10,
      labelPercentages: false,      
      labelPercentagesOverall: false, 
      hideAreas: false,
      showRecordingList: false,
      loading: false,
      areasPerUser: [],
      drawHeatmap: true,
      drawGazePoints: false,
      drawMotionTrail: false,
      motionTrailLength: 10,
      motionTrailRadius: 10
    }
  },
  computed: {
    env: function(){
      return process.env.VUE_APP_ENV
    },
    videoWidth(){
      return Math.round(this.baseWidth * (1 + this.scale/10))
    },
    videoHeight(){
      return Math.round(this.baseHeight * (1 + this.scale/10))
    },
    videoSource(){
      return this.item?.heatmapWebVid ? `${this.contentRoot}${this.item.heatmapWebVid}` : null
      // return this.videoURL // ?? ( this.env == 'local' ? "BC_LR_01.mp4" :"https://s3.eu-central-1.amazonaws.com/dev.public.avris.io/balint-test1/BC_LR_01.mp4" )
    },
    sections(){
      return this.item?.sections?.map(s => ({
        ...s, 
        width: Math.round((s.to - s.from)/this.videoDuration*100),
        left: Math.round(s.from/this.videoDuration*100)
      }))
    },
    markers(){
      return this.item?.markers?.map(m => ({
        ...m, 
        left: Math.round(m.at/this.videoDuration*100)
      }))
    },
    metadataKeys(){
      return Array.from(new Set(this.recordings.flatMap(r => Object.keys(r.metadata)))).filter(m => !m.startsWith('cp_'))
    },
    // areasPerUser(){
    //   return this.recordings.map(rec => ({
    //     userId: rec.userId,
    //     metadata: rec.metadata,
    //     areas: this.areas.map((a,index2) => ({
    //       label: a.label,
    //       temp00: index2,
    //       temp000: rec.areaSamples[index2],
    //       temp0: rec,
    //       temp1: rec.areaSamples ? rec.areaSamples[index2] : 0,
    //       temp2: rec.x.length,
    //       coverage: ((rec.areaSamples ? rec.areaSamples[index2] : 0)/rec.x.length*100)
    //     }))
    //   }))  
    // }
    selectedRecordings(){
      return this.recordings.filter(r => r.checked)
    }
  },
  methods: {
    allSelectedChanged(ev){
      this.recordingList.forEach(r => r.checked = ev.srcElement.checked) // this.$set(r, 'checked', ev.srcElement.checked))
    },    
    videoMetadataLoaded(){
      this.videoDuration = this.$refs.video?.duration     
      let aspectRatio =  this.$refs.video?.videoWidth / this.$refs.video?.videoHeight
      this.baseHeight = Math.round(this.baseWidth / aspectRatio)
      if(this.sections)
          this.$refs.sections.style.width = (this.$refs.mediatimerange.offsetWidth - 18) + 'px'
    },
    areasToClipboard(exportToFile){
      let csv = "Label,X,Y,Width,Height,Current coverage %,Overall coverage %"
      this.areas.forEach(area => {
        csv += `\n${area.label},${area.xN},${area.yN},${area.wN},${area.hN},${area.liveCoverage ?? 0},${area.aggregatedCoverage ?? 0}`
      })
      if(exportToFile){
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `session-${this.sessionId}-segment-${this.segmentId}-item-${this.item.id}-areas-export.csv`);
      }
      else{
        navigator.clipboard.writeText(csv)
        this.$refs.snackbar.show('Areas copied to clipboard.')
      }
    },
    areasPerUserToClipboard(exportToFile){
      // headers
      let csv = ""
      this.metadataKeys.forEach(key => {
        csv += `${key},`
      })
      csv += 'UserId'
      this.areas.forEach(area => {
        csv += `,${area.label}`
      })

      // overall coverage
      csv += '\n'
      this.metadataKeys.forEach(() => {
        csv += `,`
      })
      csv += 'Overall'
      this.areas.forEach(area => {
        csv += `,${area.aggregatedCoverage.toFixed(2)}`
      })

      // per user breakdown
      this.areasPerUser.forEach(item => {
        csv += '\n'
        this.metadataKeys.forEach(key => {
          csv += `${item.metadata[key]},`
        })
        csv += item.userId
        item.areas.forEach(a => {
          csv += `,${a.coverage.toFixed(2)}`
        })
      })

      if(exportToFile){
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `session-${this.sessionId}-segment-${this.segmentId}-item-${this.item.id}-areas-per-user-export.csv`);
      }
      else{
        navigator.clipboard.writeText(csv)
        this.$refs.snackbar.show('Areas per user copied to clipboard.')
      }
    },
    exportRawDataCsv(exportToFile){
      let maxSamples = _.max(this.recordings.map(r => r.x.length))      
      let sampleRate = this.recordings[0].sampleRate

      // headers
      let csv = 'UserId'
      this.metadataKeys.forEach(key => {
        csv += `,${key}`
      })
      for (let i = 0; i < maxSamples; i++) {
        csv += `,${i/sampleRate}`        
      }

      // data
      this.recordings.forEach(rec => {
        csv += `\n${rec.userId}`

        this.metadataKeys.forEach(key => {
          csv += `,${rec.metadata[key]}`
        })

        for (let i = 0; i < maxSamples; i++) {
          csv += `,${rec.x[i]}_${rec.y[i]}`        
        }
      })

      if(exportToFile){
        let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `session-${this.sessionId}-segment-${this.segmentId}-item-${this.item.id}-head-movement-raw-data-export.csv`);
      }
      else {
        navigator.clipboard.writeText(csv)
        this.$refs.snackbar.show('Raw recordings with metadata copied to clipboard.')
      }

    },
    recordingToClipboard(rec){
      // headers
      let csv = ""
      // Object.keys(rec.metadata).forEach(key => {
      //   csv += `${key},`
      // })
      csv += `Calculated Time (s) from ${rec.sampleRate}/s sample rate,X,Y`

      // data
      for (let index = 0; index < Math.min(rec.x.length, rec.y.length); index++) {        
        csv += '\n'
        // if(rec.metadata?.length)
        //   rec.metadata.forEach(meta => {
        //     csv += `${meta},`          
        //   })
        csv += `${(1/rec.sampleRate*index).toFixed(2)},${rec.x[index]},${rec.y[index]}`
      }

      navigator.clipboard.writeText(csv)
      this.$refs.snackbar.show('Raw gaze data of user copied to clipboard.')
    },
    addArea(){      
      this.areas.push({
        w: 70,
        h: 90,        
        x: 0,
        y: 0,
        wN: 70/this.videoWidth,
        hN: 90/this.videoHeight,
        xN: 0,
        yN: 0,
        label: `label${this.areas.length + 1}`,
        liveCoverage: 0,
        aggregatedCoverage: 0
      })
    },
    removeArea(index){
      this.areas.splice(index, 1)
    },
    async loadAreas(){
      this.loading = true
      this.$nprogress.start()      
      let url = `analytics/metadata?sessionId=${this.sessionId}&segmentId=${this.segmentId}&itemId=${this.item.id}`      
      let resp = await axios({ url: url, method: 'GET' }) 
      try{
        this.areas = JSON.parse(resp.data.map(d => d.areasJson));     
        this.scaleAreas()
      }
      catch{
        this.areas = []
      }
      this.$refs.snackbar.show(`${this.areas.length} areas loaded from server.`)  
      this.loading = false
      this.$nprogress.done()
    },
    async saveAreas(){
      this.loading = true
      this.$nprogress.start()
      let url = `analytics/metadata?sessionId=${this.sessionId}&segmentId=${this.segmentId}&itemId=${this.item.id}`    
      let data = JSON.stringify(this.areas)  
      // eslint-disable-next-line 
      await axios.post(url, data
      , {
        headers: {
            'Content-Type': 'application/json',
        }
      }
      )   
      this.$refs.snackbar.show('Areas saved on server.')    
      this.loading = false
      this.$nprogress.done()
    },
    recalcArea(){
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      let area = this.areas[areaindex]
      area.liveCoverage = slidingWindowDataPoints.filter(d => 
        d.xN > area.xN && d.xN < (area.xN + area.wN) && d.yN > area.yN && d.yN < (area.yN + area.hN)
      ).length / slidingWindowDataPoints.length * 100
      area.liveCoverage = (!area.liveCoverage || isNaN(area.liveCoverage)) ? 0 : area.liveCoverage
    },
    scaleAreas(){
      // redraw areas for new player size      
      this.areas = this.areas.map(a => {
        a.x = a.xN * this.videoWidth
        a.y = a.yN * this.videoHeight
        a.w = a.wN * this.videoWidth
        a.h = a.hN * this.videoHeight
        return a
      })               
    },
    onAreaResize: function (x, y, width, height) {
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      //console.log(areaindex)
      this.areas[areaindex].x = x
      this.areas[areaindex].y = y
      this.areas[areaindex].w = width
      this.areas[areaindex].h = height
      this.areas[areaindex].wN = width/localThis.videoWidth,
      this.areas[areaindex].hN = height/localThis.videoHeight,
      this.areas[areaindex].xN = x/localThis.videoWidth,
      this.areas[areaindex].yN = y/localThis.videoHeight
    },
    onAreaDrag: function (x, y) {
      let areaindex = this.$refs.areas.querySelector('.area.active').dataset.areaindex
      this.areas[areaindex].x = x
      this.areas[areaindex].y = y
      this.areas[areaindex].xN = x/localThis.videoWidth,
      this.areas[areaindex].yN = y/localThis.videoHeight   
    },
    async loadRecordings(download){
      this.loading = true
      this.$nprogress.start()      

      let url = `sessions/${this.sessionId}/recordings?segment=${this.segmentId}&item=${this.item.id}`      
      let query
      switch (this.serial) {
        case "-1":
          query = ''
          break;
        case null:
          query = '&archived=false'
          break;      
        default:
          query = `&serial=${this.serial}`
          break;
      }   
      url += query
      if(download)
        url += '&presign=true'
      let resp = await axios({ url: url, method: 'GET' }) 

      if(download){
        this.recordings.length = 0
        let promises = resp.data.map(rec => axios.get(rec.blobUrl, {transformRequest: (data, headers) => {
            delete headers.common['Authorization'];
            return data;
          }})
        )

        await Promise.all(promises) 
          .then((data) => {
            data.forEach((rec, i) => {           
              resp.data[i].x = rec.data.x
              resp.data[i].y = rec.data.y   
              resp.data[i].checked = true        
              this.recordings.push(resp.data[i])
            });
          })
          .catch((err) => {
            console.log(err)
          })
        }

        this.recordingList = resp.data

        this.loading = false
        this.$nprogress.done()
    },
    seekRecordings(/*ev*/){
      // use ev.target.currentTime or this.$refs.video.currentTime
      // reset heatmap to current sample when seek happens      
      slidingWindowDataPoints.length = 0
      // render current sample if video paused (for example if prof is just clicking around and examining individual video frames)
      if(this.$refs.video.paused)
        this.renderSamples()
    },
    playRecordings(){
      sampleInterval = Math.round(1000/this.sampleRate)
      this.replayInterval = setInterval(this.renderSamples, sampleInterval)
    },
    pauseRecordings(){
      clearInterval(this.replayInterval)
    },
    renderSamples(){      
      sample = Math.round(this.$refs.video.currentTime*1000/sampleInterval)
      //console.log(this.$refs.video.currentTime + ' ' + sample + ' ' + sampleInterval)

      // no samples available 
      if(sample >= failsafe || lastSample == sample){     
        //console.log('skipping duplicate sample render' )          
        return
      } 
      // avoid rendering same sample twice (edge-case if intervals would overlap for whatever reason)
      lastSample = sample
      
      // current samples
      let dataPoints = this.selectedRecordings.map(r =>
        ({
          xN: r.x[sample],
          yN: r.y[sample],
          x: Math.round(r.x[sample]*this.videoWidth),
          y: Math.round(r.y[sample]*this.videoHeight),
          value: 1,
          radius: this.radius
        })
      )

      // maintain samples in sliding window
      if(this.slidingWindow){
        slidingWindowDataPoints.push(...dataPoints)        
        // push out "old" samples from window
        // TODO use Queue.js if performance will be an issue, although heatmap.js uses arrays anyway
        // TODO2 here we assume all recordings have the same amount of samples, and we calculate how many samples that means in total in our window.
        // but this could act weird at the end of videos if not all users have equal number of samples. probably minor thing, but worth keeping note of this
        if (slidingWindowDataPoints.length > this.slidingWindowSamples*this.selectedRecordings.length) {              
          slidingWindowDataPoints.splice(0, this.selectedRecordings.length)
        }

        // render heatmap
        this.heatmapInstance.setData({
          max: this.dataMax,
          min: this.dataMin,
          data: slidingWindowDataPoints
        })

        // live area coverage calculations
        this.areas.forEach(area => {
          area.liveCoverage = slidingWindowDataPoints.filter(d => 
            d.xN > area.xN && d.xN < (area.xN + area.wN) && d.yN > area.yN && d.yN < (area.yN + area.hN)
          ).length / slidingWindowDataPoints.length * 100
        });

        // gaze and motion trail
        let c = this.$refs.motionTrailCanvas
        var ctx = c.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);

        if(this.drawMotionTrail){
          motionPositions.push({
            dataPoints
          })        
          //get rid of first item
          if (motionPositions.length > this.motionTrailLength) {
            motionPositions.shift()
          }

          //console.log(motionPositions)
          for (var i = 0; i < motionPositions.length; i++) {
            let ratio = (i + 1) / motionPositions.length;
            for (let j = 0; j < motionPositions[i].dataPoints.length; j++) {
              ctx.beginPath()
              ctx.arc(motionPositions[i].dataPoints[j].x, motionPositions[i].dataPoints[j].y, this.motionTrailRadius*ratio, 0, 2 * Math.PI, true)
              ctx.fillStyle = "rgba(204, 102, 153, " + ratio / 2 + ")"
              ctx.fill()
            }
          }
        }

        if(this.drawGazePoints){
          dataPoints.forEach(p => {
            ctx.fillStyle = "rgb(255, 0, 0)"
            ctx.fillRect(p.x-2, p.y-2, 5, 5)
          });
        }
      }
    },
    debug(){
      // console.log(slidingWindowDataPoints)
      // console.log(this.heatmapInstance.getData())
      // //this.heatmapInstance.repaint()
      
      // let wtf = this.heatmapInstance.getData()
      // console.log(wtf.data[0])
      // wtf.data[0].value = 5

      // this.heatmapInstance.setData({
      //   max: this.dataMax,
      //   min: this.dataMin,
      //   data: //[wtf.data[0]]
      //   [{
      //     x: 293.07,
      //     y: 147.12,
      //     value: 5
      //   }]
      // })
      // console.log(this.heatmapInstance.getData())

      // var nuConfig = {
      //   radius: this.radius,
      //   maxOpacity: 1,
      //   minOpacity: 0,
      //   blur: .85
      // }
      // this.heatmapInstance.configure(nuConfig)

      // console.log(this.radius)
      // console.log(this.heatmapInstance.getData())
    },
    aggregateAreaCoverage(){
      // init
      let totalSamples = 0
      this.areas.forEach(area => {
        area.coveredSampleCount = 0
      })
      // calc avg for each area, how many samples were in total inside the area
      this.recordings.forEach(rec => {
        rec.areaSamples = Array(this.areas.length).fill(0)
        
        for (let i = 0; i < rec.x.length; i++) {          
          this.areas.forEach((area,index) => {
            if(rec.x[i] > area.xN && rec.x[i] < (area.xN + area.wN) && rec.y[i] > area.yN && rec.y[i] < (area.yN + area.hN)){
              area.coveredSampleCount++
              rec.areaSamples[index]++
            }
          })
          totalSamples++
        }   
      })

      this.areas.forEach(area => {
        area.aggregatedCoverage = area.coveredSampleCount / totalSamples * 100
      })

      this.areasPerUser = this.recordings.map(rec => ({
        userId: rec.userId,
        metadata: rec.metadata,
        areas: this.areas.map((a,index2) => ({
          label: a.label,          
          coverage: ((rec.areaSamples ? rec.areaSamples[index2] : 0)/rec.x.length*100)
        }))
      }))  
    },
    async setScale(){
      this.heatmapInstance.setDataMax(this.dataMax)
      this.heatmapInstance.setDataMin(this.dataMin)
      //console.log("max: " + this.dataMax + " min: " + this.dataMin)
    }
  },
  created() {
    
  },
  async mounted(){
    this.heatmapInstance = h337.create({
      // only container is required, the rest will be defaults
      container: document.querySelector('.heatmap'),
      // maxOpacity: .9,
      // minOpacity: 0.3,
      // blur: .75
      //radius: 30
    });
    this.setScale()
    
    localThis = this

    this.$refs.video.onseeked = this.seekRecordings
    this.$refs.video.onplay = this.playRecordings
    this.$refs.video.onpause = this.pauseRecordings

    //await this.downloadRecordings()
    //this.addArea()    
  },
  watch: {
    item: async function(){
      if(this.item)
        await this.loadRecordings(false)
      else{ // in case of archived current results for example, then the component should be reset to latest state, which doesn't have any recordings right after we archived
        this.recordings = []
        this.recordingList = []
      }
    },
    selectedRecordings(){
      this.allSelected = this.selectedRecordings?.length == this.recordings?.length
    },
    scale: function(){
      //this.scaleAreas() 
      setTimeout(() => {
        this.$refs.heatmap.innerHTML = ''
        this.heatmapInstance = h337.create({
          container: document.querySelector('.heatmap'),
        })
        window.dispatchEvent(new Event('resize'))
        if(this.sections)
          this.$refs.sections.style.width = (this.$refs.mediatimerange.offsetWidth - 18) + 'px'  

        setTimeout(() => this.scaleAreas(), 300)

      }, 300)  // workaround for vdr components to update parent bounds + heatmap instance recalculate canvas bounds (both libraries automatically self-adjust during init, but not on resize)
    },
    serial: async function(){
      if(this.item)
        await this.loadRecordings(false)
    }
    // conditions: function (/*newItem, oldItem*/) {
    //   this.segmentResultsCondition = this.conditions ? this.conditions[1] : undefined      
    // },
    // questions: function(){
    //   setTimeout(this.setAnimationMaxHeight, 1000)
    // }
  },
}
</script>



<style lang="scss">

.heatmapcontainer{
  position: relative;
  min-width: 600px;  
  min-height: 344px;
  margin-top: 10px;

  .heatmap{
    min-width: 600px;
    min-height: 300px;
    //border: 1px solid greenyellow;
    position: absolute !important;
    top: 0px;
    left: 0px;
    z-index: 10;

    canvas{
      width: 100%;
      height: 100%;
    }
  }

  #motionTrailCanvas{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
  }

  video{
    //position: absolute;
    //top: 0px;
    //left: 0px;
    // width: 600px;
    height: auto;
  }

  .sections{
    width: 364px;
    margin-left: 55px;
    position: relative;
    height: 40px;

    .section {
      border: 4px solid white;
      border-top: 4px solid $green1;
      font-size: 12px;
      position: absolute;
    }

    .marker{
      position: absolute;
      border-radius: 50px;
      width: 12px;
      height: 12px;
      overflow: visible;
      background-color: $green2;
      border: 1px dotted white;
      top: -4px;
      cursor: pointer;
      transform: translateX(-50%);

      &:hover{
        span{
          display: inline;
        }
      }

      span{
        display: none;
        position: absolute;
        white-space: nowrap;
        top: -25px;
        background-color: $green2;
        color: white;
        font-size: 12px;
        padding: 5px;
        transform: translate(-45%);
      }
    }
  }

  .mediacontroller{
    // position: absolute;
    top: 0px;
    min-width: 600px;  
    min-height: 344px;
    left: 0px;
  }

  media-control-bar{
    opacity:1;
  }

  .areas{
    min-width: 600px;
    min-height: 300px;
    position: absolute !important;
    top: 0px;
    left: 0px;
    z-index: 20;

    .vdr{
      border: 1px dashed greenyellow;
      
      span.label{
        background-color: greenyellow;
        //color: white;
        padding: 0 2px;
        font-size: 10px;
        vertical-align: top;
      }
    }
  }
}

.arealist{
  margin-top: 20px;
  width: 600px;
  min-height: 100px;
  // position: absolute;
  // top: 180px;
  // left: 650px;
  // border:1px solid rgb(221, 221, 221);
  // padding: 20px;

  input[type=text]{
    width: 100px;
    height: 20px;
  }
}

.recordinglist{
  text-align: left;
  th, td{
    padding-right: 10px;
  }
}

.userlist{
  width: 600px;
  min-height: 100px;
  
  // position: absolute;
  // top: 180px;
  // left: 1100px;
  // padding: 20px;
  // border:1px solid rgb(221, 221, 221);
}

</style>
